@import 'variables';
.modal-hg {
  width: 90%;
  @media (min-width: 1200px) {
    width: 1170px;
  }
}

.modal-content {
  border-radius: 0;
}

.modal-header {
  display: flex;
  height: 50px;
  padding: 10px 20px;
  border: 0;
  background: $qe-secondary-background-color;
  .modal-title {
    @extend %text-elipsis;
    flex: 1;
    color: $qe-secondary-font-color;
    font-family: 'Varela Round', sans-serif;
    font-size: 26px;
    line-height: 30px;
  }
  .education-package-info {
    padding: 7.5px 15px;
    margin-right: 15px;
    background: #f6f6f6;
    border-radius: 14px;
    color: $qe-tertiary-font-color;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    &:hover {
      opacity: 0.8;
    }
  }
  .modal-close {
    svg {
      fill: $qe-secondary-font-color;
    }
  }
}

.modal-body {
  padding: 20px;
  .ivh-treeview-wrapper {
    margin-top: 10px;
  }
  .source-thumbnail-preview {
    svg {
      display: flex;
      margin: auto;
      fill: $qe-tertiary-font-color;
    }
    img.own-source-preview {
      display: flex;
      height: 240px;
      margin: auto;
    }
  }
  .additional-info-container {
    margin-top: 15px;
    p {
      font-weight: 700;
    }
  }
}

.modal-thumbnail-wrapper {
  display: flex;
  .thumbnail-upload-wrapper {
    flex: 3;
    padding: 25px 0;
    border: 1px dashed #22466a;
    margin-right: 15px;
    background: #f6f6f6;
    border-radius: 1px;
    .thumbnail-upload-button {
      margin-bottom: 15px;
      text-align: center;
      .thumbnail-upload-button-icon {
        margin-bottom: 10px;
        text-align: center;
        svg {
          fill: $qe-tertiary-font-color;
        }
      }
    }
    .thumbnail-upload-message {
      color: $qe-tertiary-font-color;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 400;
      text-align: center;
      .has-error {
        color: $qe-error-font-color;
      }
    }
  }
  .thumbnail-preview-wrapper {
    position: relative;
    flex: 2;
    .thumbnail-preview,
    .thumbnail-default {
      width: 100%;
      height: 100%;
    }
    .thumbnail-preview {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .thumbnail-default {
      position: relative;
      background: #d4d9dd;
      div {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        text-align: center;
        transform: translateY(-50%);
        svg {
          fill: $qe-tertiary-font-color;
        }
      }
    }
    .cover-blur {
      -webkit-filter: brightness(0.75);
      -moz-filter: brightness(0.75);
      -o-filter: brightness(0.75);
      -ms-filter: brightness(0.75);
      filter: brightness(0.75);
    }
    .cover-function {
      position: absolute;
      top: 50%;
      left: 50%;
      color: $qe-secondary-font-color;
      cursor: pointer;
      transform: translate(-50%, -50%);
      // &:hover {
      //    color: #ddd;
      // }
      .cover-function-remove {
        transform: translate(50%, -50%);
      }
    }
  }
  &.full-width {
    display: block;
    height: 250px;
    .thumbnail-upload-wrapper {
      height: 100%;
      margin-right: 0;
      .thumbnail-upload-wrapper-inner {
        position: relative;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        .thumbnail-upload-button {
          margin-bottom: 0px;
          .thumbnail-upload-button-icon {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

.modal-footer {
  border-top-color: #22466a;
}

.node-translator-modal {
  .row:last-child .form-group {
    margin-bottom: 0;
  }
  .modal-footer {
    padding-top: 0;
    border-top-width: 0;
  }
}
