.quiz-content {
  .question-page {
    .question-content {
      .question-wrapper {
        position: relative;
        min-height: 80px;
        padding: 15px;
        border-radius: 18px;
        font-family: 'Varela Round', sans-serif;
        .question-index {
          position: absolute;
          top: 10px;
          right: 20px;
          font-size: 16px;
        }
        .question {
          padding-top: 10px;
          font-size: 26px;
        }
      }
      .sources {
        margin: 30px auto 0 auto;
        .source-metadata {
          padding-bottom: 0;
        }
      }
      .question-help {
        margin-top: 10px;
      }
      .answers {
        padding: 0;
        margin: 30px 0;
        &.multi {
          margin-top: 10px;
        }
        color: $qe-tertiary-font-color;
        list-style: none;
        .answer {
          padding: 5px 20px;
          border: 1px solid $qe-tertiary-font-color;
          margin-bottom: 10px;
          border-radius: 2px;
          cursor: pointer;
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          font-weight: 500;
        }
        .answer.submitted,
        .answer.submitted label {
          cursor: not-allowed;
        }
        .answer:last-child {
          margin-bottom: 0px;
        }
        .answer:focus {
          outline: none;
        }
        .answer:not(.submitted):hover {
          border: 1px solid $qe-tertiary-font-color;
        }
        .answer.correct,
        .answer.incorrect {
          border-width: 1px;
          border-style: solid;
        }
        .answer.correct,
        .value-answer.correct {
          border-color: $qe-success-color;
          background-color: $qe-success-background-color;
          box-shadow: 0px 0px 3px $qe-success-color;
          color: $qe-success-color;
        }
        .answer.incorrect,
        .value-answer.incorrect {
          border-color: $qe-error-border-color;
          background: $qe-error-background-color;
          box-shadow: 0px 0px 3px $qe-error-border-color;
          color: $qe-error-border-color;
        }
      }
      .pairs-wrapper {
        margin: 30px 0;
        .pairs-info {
          margin: 0 30px 10px 30px;
        }
        .pairs-wrapper-inner {
          display: flex;
          .dndDraggingSource {
            display: none;
          }
          .dndPlaceholder {
            display: block;
            height: 34px;
            margin-bottom: 10px;
            background-color: #eee;
          }
          .dndPlaceholder:last-child {
            margin-bottom: 0px;
          }
          .pairs-items {
            flex: 1;
            padding: 15px;
            margin: 0;
            list-style-type: none;
            .pairs-item {
              padding: 6px 12px;
              border: 1px solid $qe-tertiary-font-color;
              margin-bottom: 10px;
              background: $qe-primary-background-color;
              border-radius: 2px;
              color: $qe-tertiary-font-color;
              cursor: move;
              font-family: 'Montserrat', sans-serif;
              font-size: 13px;
              font-weight: 500;
              i {
                margin-right: 10px;
              }
            }
            .pairs-item.submitted {
              cursor: not-allowed;
            }
            .pairs-item:last-child {
              margin-bottom: 0px;
            }
            .pairs-item.correct {
              border-color: $qe-success-color;
              box-shadow: 0px 0px 3px $qe-success-color;
            }
            .pairs-item.incorrect {
              border-color: $qe-error-border-color;
              background: $qe-error-background-color;
              box-shadow: 0px 0px 3px $qe-error-border-color;
              color: $qe-error-border-color;
            }
            .pairs-item:not(.submitted):hover {
              border-color: $qe-tertiary-font-color;
            }
          }
        }
      }
      .question-result {
        margin-bottom: 30px;
        .icon {
          width: 60px;
          height: 60px;
          border: 2px solid $qe-tertiary-font-color;
          margin: 0 auto;
          margin-bottom: 5px;
          border-radius: 50%;
          color: $qe-tertiary-font-color;
          font-size: 30px;
          line-height: 55px;
          text-align: center;
        }
        .info,
        .correct {
          margin: 10px 0;
          color: $qe-tertiary-font-color;
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          font-weight: 500;
          line-height: 13px;
          text-align: center;
        }
        .correct-pairs {
          margin-top: 10px;
          span {
            display: inline-block;
            padding-right: 5px;
            padding-left: 5px;
          }
        }
      }
      .function-wrapper {
        text-align: center;
      }
    }
  }
  .result-page {
    .result-label {
      color: $qe-tertiary-font-color;
      font-family: 'Montserrat', sans-serif;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }
    .result {
      margin: 30px auto;
    }
    .function-wrapper {
      text-align: center;
    }
  }
}

.progress-pie-chart {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: #e5e5e5;
  border-radius: 50%;
}

.progress-pie-chart.gt-50 {
  background-color: $qe-success-color;
}

.ppc-progress {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  clip: rect(0, 200px, 200px, 100px);
  content: '';
}

.ppc-progress .ppc-progress-fill {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  width: 200px;
  height: 200px;
  background: $qe-success-color;
  border-radius: 50%;
  clip: rect(0, 100px, 200px, 0);
  content: '';
  transform: rotate(60deg);
}

.gt-50 .ppc-progress {
  clip: rect(0, 100px, 200px, 0);
}

.gt-50 .ppc-progress .ppc-progress-fill {
  background: #e5e5e5;
  clip: rect(0, 200px, 200px, 100px);
}

.ppc-percents {
  position: absolute;
  top: calc(50% - 173.91304px / 2);
  left: calc(50% - 173.91304px / 2);
  display: table;
  width: 173.91304px;
  height: 173.91304px;
  background: #fff;
  border-radius: 50%;
  content: '';
  text-align: center;
}

.ppc-percents span {
  display: block;
  color: $qe-success-color;
  font-size: 2.6em;
  font-weight: bold;
}

.pcc-percents-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.progress-pie-chart {
  margin: 50px auto 0;
}
.answers .answer .checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .quiz-content .question-page .question-content .pairs-wrapper .pairs-wrapper-inner .pairs-items {
    margin-right: 30px;
    margin-left: 30px;
  }
}
