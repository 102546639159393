@import 'variables';
@mixin productColorSchemeModalHeader($backgroundColor, $fontColor) {
  background: $backgroundColor;
  .modal-title {
    color: $fontColor;
  }
  .modal-close {
    svg {
      fill: $fontColor;
    }
  }
}

@mixin productColorSchemeModalBody($backgroundColor, $fontColor) {
  .form-control {
    border-color: $backgroundColor;
    color: $backgroundColor;
  }
  .form-control:focus {
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($backgroundColor, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($backgroundColor, 0.6);
  }
  .btn-tertiary {
    border-color: $backgroundColor;
    color: $backgroundColor;
  }
  .divider {
    border-color: $backgroundColor;
  }
  .comment-list {
    &:nth-child(even) {
      .comment-list-details {
        color: $fontColor;
      }
      background: $backgroundColor;
      color: $fontColor;
      .form-control {
        border-color: $fontColor;
      }
      .form-control:focus {
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($fontColor, 0.6);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($fontColor, 0.6);
      }
    }
  }
}

.modal-header {
  &.scheme_1 {
    @include productColorSchemeModalHeader(
      $qe-product-background-color-1,
      $qe-product-font-color-1
    );
  }
  &.scheme_2 {
    @include productColorSchemeModalHeader(
      $qe-product-background-color-2,
      $qe-product-font-color-2
    );
  }
  &.scheme_3 {
    @include productColorSchemeModalHeader(
      $qe-product-background-color-3,
      $qe-product-font-color-3
    );
  }
  &.scheme_4 {
    @include productColorSchemeModalHeader(
      $qe-product-background-color-4,
      $qe-product-font-color-4
    );
  }
  &.scheme_5 {
    @include productColorSchemeModalHeader(
      $qe-product-background-color-5,
      $qe-product-font-color-5
    );
  }
  &.scheme_6 {
    @include productColorSchemeModalHeader(
      $qe-product-background-color-6,
      $qe-product-font-color-6
    );
  }
  &.scheme_7 {
    @include productColorSchemeModalHeader(
      $qe-product-background-color-7,
      $qe-product-font-color-7
    );
  }
  &.scheme_8 {
    @include productColorSchemeModalHeader(
      $qe-product-background-color-8,
      $qe-product-font-color-8
    );
  }
  &.scheme_9 {
    @include productColorSchemeModalHeader(
      $qe-product-background-color-9,
      $qe-product-font-color-9
    );
  }
  &.scheme_10 {
    @include productColorSchemeModalHeader(
      $qe-product-background-color-10,
      $qe-product-font-color-10
    );
  }
  &.scheme_11 {
    @include productColorSchemeModalHeader(
      $qe-product-background-color-11,
      $qe-product-font-color-11
    );
  }
  &.scheme_12 {
    @include productColorSchemeModalHeader(
      $qe-product-background-color-12,
      $qe-product-font-color-12
    );
  }
  &.custom_1 {
    @include productColorSchemeModalHeader($qe-source-decorator-color, #f6f6f6);
  }
}

.modal-body {
  &.scheme_1 {
    @include productColorSchemeModalBody($qe-product-background-color-1, $qe-product-font-color-1);
  }
  &.scheme_2 {
    @include productColorSchemeModalBody($qe-product-background-color-2, $qe-product-font-color-2);
  }
  &.scheme_3 {
    @include productColorSchemeModalBody($qe-product-background-color-3, $qe-product-font-color-3);
  }
  &.scheme_4 {
    @include productColorSchemeModalBody($qe-product-background-color-4, $qe-product-font-color-4);
  }
  &.scheme_5 {
    @include productColorSchemeModalBody($qe-product-background-color-5, $qe-product-font-color-5);
  }
  &.scheme_6 {
    @include productColorSchemeModalBody($qe-product-background-color-6, $qe-product-font-color-6);
  }
  &.scheme_7 {
    @include productColorSchemeModalBody($qe-product-background-color-7, $qe-product-font-color-7);
  }
  &.scheme_8 {
    @include productColorSchemeModalBody($qe-product-background-color-8, $qe-product-font-color-8);
  }
  &.scheme_9 {
    @include productColorSchemeModalBody($qe-product-background-color-9, $qe-product-font-color-9);
  }
  &.scheme_10 {
    @include productColorSchemeModalBody(
      $qe-product-background-color-10,
      $qe-product-font-color-10
    );
  }
  &.scheme_11 {
    @include productColorSchemeModalBody(
      $qe-product-background-color-11,
      $qe-product-font-color-11
    );
  }
  &.scheme_12 {
    @include productColorSchemeModalBody(
      $qe-product-background-color-12,
      $qe-product-font-color-12
    );
  }
  &.custom_1 {
    @include productColorSchemeModalBody($qe-source-decorator-color, #f6f6f6);
  }
  .new-comment-form {
    .new-comment-control {
      margin: 11px 0px;
    }
  }
  .comment-list {
    padding: 16px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    &:nth-child(even) {
      background: rgba(#d7dce1, 0.3);
    }
    &:nth-child(odd) {
      background: #d7dce1;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    .comment-list-details {
      margin-bottom: 15px;
      color: $qe-product-font-color-1;
      font-weight: 500;
    }
    .comment-content {
      font-weight: 400;
    }
    .comment-list-control {
      .comment-control-icon {
        margin-right: 6px;
        svg {
          width: 13px;
          height: 13px;
        }
        &:last-child {
          margin-right: 0px;
        }
        svg {
          fill: $qe-tertiary-font-color;
        }
      }
    }
    .form-control.comment-list-edit-area {
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      resize: vertical;
    }
  }
}
