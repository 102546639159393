.external-page {
  .file-container {
    height: 56px;
    padding: 15px 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    span {
      line-height: 26px;
    }
  }
}
