@import 'variables';
.admin-package-subheader,
.education-package-subheader {
  height: 130px;
}

.education-package-subheader-wrapper,
.product-page-subheader-wrapper {
  background: #22466a;
}

.product-page-subheader,
.product-subheader {
  height: 60px;
}

.admin-package-subheader,
.education-package-subheader,
.product-page-subheader {
  background: $qe-secondary-background-color;
  .subheader-top {
    .title {
      color: $qe-secondary-font-color;
    }
    .menu-wrapper {
      .menu-item {
        svg {
          fill: $qe-secondary-font-color;
        }
      }
    }
  }
  .page-top-arrow {
    &:before,
    &:after {
      border-bottom-color: $qe-secondary-background-color;
    }
  }
}

@mixin productSubheaderColorScheme($backgroundColor, $fontColor) {
  background: $backgroundColor;
  color: $fontColor;
  svg {
    fill: $fontColor;
  }
  .page-top-arrow {
    background-color: $qe-secondary-background-color;
    &:before,
    &:after {
      border-bottom-color: $backgroundColor;
    }
  }
}

.product-subheader.scheme_1 {
  @include productSubheaderColorScheme($qe-product-background-color-1, $qe-product-font-color-1);
}

.product-subheader.scheme_2 {
  @include productSubheaderColorScheme($qe-product-background-color-2, $qe-product-font-color-2);
}

.product-subheader.scheme_3 {
  @include productSubheaderColorScheme($qe-product-background-color-3, $qe-product-font-color-3);
}

.product-subheader.scheme_4 {
  @include productSubheaderColorScheme($qe-product-background-color-4, $qe-product-font-color-4);
}

.product-subheader.scheme_5 {
  @include productSubheaderColorScheme($qe-product-background-color-5, $qe-product-font-color-5);
}

.product-subheader.scheme_6 {
  @include productSubheaderColorScheme($qe-product-background-color-6, $qe-product-font-color-6);
}

.product-subheader.scheme_7 {
  @include productSubheaderColorScheme($qe-product-background-color-7, $qe-product-font-color-7);
}

.product-subheader.scheme_8 {
  @include productSubheaderColorScheme($qe-product-background-color-8, $qe-product-font-color-8);
}

.product-subheader.scheme_9 {
  @include productSubheaderColorScheme($qe-product-background-color-9, $qe-product-font-color-9);
}

.product-subheader.scheme_10 {
  @include productSubheaderColorScheme($qe-product-background-color-10, $qe-product-font-color-10);
}

.product-subheader.scheme_11 {
  @include productSubheaderColorScheme($qe-product-background-color-11, $qe-product-font-color-11);
}

.product-subheader.scheme_12 {
  @include productSubheaderColorScheme($qe-product-background-color-12, $qe-product-font-color-12);
}

.admin-package-subheader,
.education-package-subheader,
.product-page-subheader,
.product-subheader {
  .subheader-top {
    margin: 0;
    .title {
      @extend %text-elipsis;
      margin: 6px 0;
      font-family: 'Varela Round', sans-serif;
      font-size: 26px;
      line-height: 28px;
      text-align: center;
      a {
        @extend %text-elipsis;
        display: block;
        color: inherit;
        text-decoration: none;
      }
    }
    .menu-wrapper {
      padding: 0;
    }
    .menu {
      height: 28px;
      margin: 6px 0px 6px 0px;
      text-align: right;
      &.menu-product {
        display: flex;
        height: 40px;
        margin-top: 0px;
        margin-bottom: 0px;
        text-align: left;
        .menu-item svg {
          width: auto;
          height: 40px;
        }
        .product-type {
          align-self: center;
          font-family: 'Montserrat', sans-serif;
          font-size: 15px;
          font-weight: 400;
        }
      }
      .menu-item {
        display: inline-block;
        margin: 0 10px;
      }
    }
  }
  .tab-selector {
    text-align: center;
    .tab-item {
      position: relative;
      width: 135px;
      height: 35px;
      border: 1px solid #fff;
      color: $qe-secondary-font-color;
      cursor: pointer;
      float: left;
      font-family: 'Varela Round', sans-serif;
      font-size: 20px;
      line-height: 35px;
      &:first-child {
        border-bottom-left-radius: 15px;
        border-top-left-radius: 15px;
      }
      &:last-child {
        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;
      }
      &:hover,
      &.active {
        background-color: $qe-primary-background-color;
        color: $qe-tertiary-font-color;
      }
    }
  }
}

.admin-package-page,
.education-package-page {
  .page-top-arrow {
    background-color: $qe-secondary-background-color;
  }
  .page-content-wrapper {
    margin-top: 30px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    .page-content {
      margin: 0;
    }
    .product-control {
      .product-control-item {
        padding-right: 10px;
        padding-left: 10px;
        margin-bottom: 30px;
        &.package-paginator {
          height: 28px;
          text-align: center;
        }
        .sort-field,
        .filter-field,
        .limit {
          select {
            height: 28px;
            padding-left: 5px;
            border: 1px solid $qe-secondary-background-color;
            background: $qe-input-background-color;
            color: $qe-tertiary-font-color;
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
          }
        }
        .sort-field,
        .filter-field {
          display: inline-block;
          select {
            width: 150px;
          }
        }
        .sorting-direction,
        .refresh {
          display: inline-block;
          width: 30px;
          height: 28px;
          vertical-align: top;
          svg {
            height: 25px;
            margin: 1.5px 0px;
            fill: $qe-tertiary-font-color;
          }
        }
        .pagination {
          margin: 0;
          li {
            &:first-child a,
            &:first-child span {
              border-bottom-left-radius: 15px;
              border-top-left-radius: 15px;
            }
            &:last-child a,
            &:last-child span {
              border-bottom-right-radius: 15px;
              border-top-right-radius: 15px;
            }
            a,
            span {
              padding: 4px 12px;
              background: $qe-primary-background-color;
              color: $qe-tertiary-font-color;
              font-family: 'Montserrat', sans-serif;
              font-size: 13px;
              &:hover {
                border-color: $qe-secondary-background-color;
                background: $qe-primary-background-color;
                color: $qe-tertiary-font-color;
              }
            }
            &.disabled a,
            &.disabled span {
              background: $qe-input-background-color;
            }
            &.active a,
            &.active a:hover,
            &.active span,
            &.active span:hover {
              border-color: $qe-input-hover-background-color;
              background: $qe-input-hover-background-color;
              color: $qe-secondary-font-color;
            }
          }
        }
        .limit {
          text-align: right;
          select {
            width: 60px;
          }
        }
      }
    }
    .new-product-thumbnail-wrapper {
      .new-product-thumbnail {
        border-color: $qe-source-decorator-color;
        .icon {
          svg {
            fill: $qe-source-decorator-color;
          }
        }
        .title {
          color: $qe-source-decorator-color;
        }
      }
    }
    .product-thumbnail-wrapper {
      .publication-status-wrapper {
        position: absolute;
        z-index: 1;
        top: 20px;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        .publication-status {
          display: inline-flex;
          height: 28px;
          padding: 5px 10px;
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          font-weight: 500;
          .publication-icon {
            margin-right: 5px;
            svg {
              width: auto;
              height: 100%;
            }
          }
        }
      }
      .product-thumbnail-image {
        &.default > div {
          svg {
            fill: $qe-secondary-background-color;
          }
        }
      }
    }
    .source-thumbnail-wrapper {
      .source-thumbnail-image {
        box-shadow: inset 0 0px 5px #0078af4d;
        &.default {
          .thumbnail-fallback {
            svg {
              fill: $qe-source-decorator-color;
            }
          }
          .thumbnail-error-types {
            position: absolute;
            top: 0;
            right: 0;
            padding: 10px;
            color: $qe-error-font-color;
          }
        }
      }
      .source-thumbnail-metadata-wrapper {
        background: $qe-source-decorator-color;
        .cutout {
          fill: $qe-source-decorator-color;
        }
        .title:after {
          background: $qe-source-decorator-color;
        }
      }
    }
    .product-thumbnail-wrapper,
    .source-thumbnail-wrapper {
      .product-thumbnail-image,
      .source-thumbnail-image {
        height: 210px;
      }
      .product-thumbnail-metadata-wrapper,
      .source-thumbnail-metadata-wrapper {
        height: 195px;
        .title {
          max-height: 60px;
        }
        .metadata {
          height: 40px;
          margin-bottom: 20px;
          font-family: 'Montserrat', sans-serif;
          font-size: 14px;
          .product-type-icon,
          .product-type {
            height: 100%;
          }
          .product-type-icon {
            margin-right: 10px;
            float: left;
            svg {
              width: auto;
              height: 100%;
              fill: $qe-secondary-font-color;
            }
          }
        }
      }
    }
    .new-source-thumbnail-wrapper {
      .new-source-thumbnail {
        border-color: $qe-source-decorator-color;
        .icon {
          svg {
            fill: $qe-source-decorator-color;
          }
        }
        .title {
          color: $qe-source-decorator-color;
        }
      }
    }
    @mixin productColorScheme($backgroundColor, $fontColor) {
      .product-thumbnail-image {
        background-color: rgba($backgroundColor, 0.2);
        &.default > div {
          svg {
            fill: $backgroundColor;
          }
        }
      }
      .product-thumbnail-metadata-wrapper {
        background: $backgroundColor;
        color: $fontColor;
        .cutout {
          fill: $backgroundColor;
        }
        .title:after {
          background-color: $backgroundColor;
          color: $fontColor;
        }
        .metadata {
          .product-type-icon {
            svg {
              fill: $fontColor;
            }
          }
        }
        .menu-wrapper {
          svg {
            fill: $fontColor;
          }
        }
      }
    }
    .product-thumbnail-wrapper.scheme_1 {
      @include productColorScheme($qe-product-background-color-1, $qe-product-font-color-1);
    }
    .product-thumbnail-wrapper.scheme_2 {
      @include productColorScheme($qe-product-background-color-2, $qe-product-font-color-2);
    }
    .product-thumbnail-wrapper.scheme_3 {
      @include productColorScheme($qe-product-background-color-3, $qe-product-font-color-3);
    }
    .product-thumbnail-wrapper.scheme_4 {
      @include productColorScheme($qe-product-background-color-4, $qe-product-font-color-4);
    }
    .product-thumbnail-wrapper.scheme_5 {
      @include productColorScheme($qe-product-background-color-5, $qe-product-font-color-5);
    }
    .product-thumbnail-wrapper.scheme_6 {
      @include productColorScheme($qe-product-background-color-6, $qe-product-font-color-6);
    }
    .product-thumbnail-wrapper.scheme_7 {
      @include productColorScheme($qe-product-background-color-7, $qe-product-font-color-7);
    }
    .product-thumbnail-wrapper.scheme_8 {
      @include productColorScheme($qe-product-background-color-8, $qe-product-font-color-8);
    }
    .product-thumbnail-wrapper.scheme_9 {
      @include productColorScheme($qe-product-background-color-9, $qe-product-font-color-9);
    }
    .product-thumbnail-wrapper.scheme_10 {
      @include productColorScheme($qe-product-background-color-10, $qe-product-font-color-10);
    }
    .product-thumbnail-wrapper.scheme_11 {
      @include productColorScheme($qe-product-background-color-11, $qe-product-font-color-11);
    }
    .product-thumbnail-wrapper.scheme_12 {
      @include productColorScheme($qe-product-background-color-12, $qe-product-font-color-12);
    }
  }
}

.product-modal,
.source-modal {
  &.modal-header {
    background: $qe-source-decorator-color;
  }
}

.product-modal {
  .product-type-wrapper {
    text-align: center;
    &.has-error {
      .icon-wrapper svg {
        fill: $qe-error-font-color;
      }
      .name {
        color: $qe-error-font-color;
      }
    }
    &:not(.inactive):hover .icon-wrapper svg,
    &.active .icon-wrapper svg {
      fill: $qe-source-decorator-color;
    }
    &.basic {
      cursor: pointer;
    }
    &:not(.basic),
    &.inactive {
      cursor: default;
    }
    .icon-wrapper {
      width: 90px;
      height: 90px;
      margin: 0 auto;
      margin-bottom: 10px;
      svg {
        width: 100%;
        height: auto;
        fill: $qe-tertiary-font-color;
      }
    }
    .name {
      color: $qe-tertiary-font-color;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 500;
    }
  }
  .product-color-row {
    margin-right: -10px;
    margin-left: -10px;
    .product-color-wrapper {
      padding-right: 10px;
      padding-left: 10px;
      .product-color {
        width: 100%;
        min-height: 50px;
        cursor: pointer;
        font-family: 'Varela Round', sans-serif;
        font-size: 24px;
        line-height: 50px;
        opacity: 0.4;
        text-align: center;
        &:hover,
        &.active {
          opacity: 1;
        }
        &.scheme_1 {
          background: $qe-product-background-color-1;
          color: $qe-product-font-color-1;
        }
        &.scheme_2 {
          background: $qe-product-background-color-2;
          color: $qe-product-font-color-2;
        }
        &.scheme_3 {
          background: $qe-product-background-color-3;
          color: $qe-product-font-color-3;
        }
        &.scheme_4 {
          background: $qe-product-background-color-4;
          color: $qe-product-font-color-4;
        }
        &.scheme_5 {
          background: $qe-product-background-color-5;
          color: $qe-product-font-color-5;
        }
        &.scheme_6 {
          background: $qe-product-background-color-6;
          color: $qe-product-font-color-6;
        }
        &.scheme_7 {
          background: $qe-product-background-color-7;
          color: $qe-product-font-color-7;
        }
        &.scheme_8 {
          background: $qe-product-background-color-8;
          color: $qe-product-font-color-8;
        }
        &.scheme_9 {
          background: $qe-product-background-color-9;
          color: $qe-product-font-color-9;
        }
        &.scheme_10 {
          background: $qe-product-background-color-10;
          color: $qe-product-font-color-10;
        }
        &.scheme_11 {
          background: $qe-product-background-color-11;
          color: $qe-product-font-color-11;
        }
        &.scheme_12 {
          background: $qe-product-background-color-12;
          color: $qe-product-font-color-12;
        }
      }
    }
  }
  .external-product-source {
    margin-bottom: 20px;
  }
}

.upload-table {
  color: #22466a;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 700;
  th {
    border-bottom: 1px solid #22466a;
  }
  svg {
    fill: #22466a;
  }
  .progress {
    margin-bottom: 0;
  }
}

.internal-source,
.external-source .supported-providers {
  color: $qe-tertiary-font-color;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
}

.internal-source {
  a,
  a:hover,
  a:focus,
  a:visited,
  a:active {
    color: $qe-tertiary-font-color;
    .institution-name {
      font-weight: 500;
    }
  }
  .internal-source-preview-wrapper {
    margin-bottom: 0;
  }
}

.own-source-preview-wrapper,
.external-source-preview-wrapper,
.internal-source-preview-wrapper {
  img {
    max-width: 100%;
  }
}

.external-source {
  .supported-providers {
    margin-top: 5px;
  }
  .external-source-preview-wrapper {
    margin-bottom: 0;
    .source-variant {
      margin-bottom: 15px;
      line-height: 200px;
      opacity: 0.35;
      text-align: center;
      &.selected {
        opacity: 1;
      }
      img {
        max-height: 200px;
      }
    }
  }
}

.own-source {
  .own-source-preview-wrapper {
    margin-bottom: 0;
  }
}

.text-source .text-editor .panel-body {
  height: 200px;
}
