wordfind-printable {
  width: 297mm;
  margin: 0 auto;
  background: none !important;
  page-break-inside: avoid;

  .wordfind-cover {
    margin-bottom: 30px;
    page-break-after: always;
    .title {
      margin-bottom: 30px;
      text-align: center;
    }
    img {
      display: block;
      height: 200px;
      margin: 30px auto;
    }
  }

  .wordfind-printable {
    background: white;
  }

  .wordfind-content {
    padding: 0 !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    page-break-inside: avoid;
    .word-search-wrapper {
      padding-left: 0px;
      .word-table {
        padding-left: 0px;
        .words {
          padding-left: 15px !important;
          .word {
            display: block !important;
          }
        }
      }
    }
  }

  .word-search-wrapper,
  .fulltext-wrapper {
    margin-bottom: 0 !important;
  }

  .fulltext-wrapper {
    div {
      overflow: hidden;
      height: 210mm;
    }
    &.columned div {
      column-count: auto;
      column-fill: auto;
      column-gap: 10mm;
      column-width: 50mm;
    }
  }

  .source-wrapper {
    height: 150px;
    margin-top: 30px;
  }
}
