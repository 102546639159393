quiz-printable {
  display: block;
  width: 210mm;
  margin: 0 auto;
  background: none !important;
  .quiz-printable {
    background: white;
  }

  .quiz-content {
    padding: 0 !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .welcome-page {
    margin-bottom: 30px;
    page-break-after: always;
    .title {
      margin-bottom: 30px;
      text-align: center;
    }
    img {
      display: block;
      height: 200px;
      margin: 30px auto;
    }
  }

  .question-page {
    border-top: 1px solid #000000;
    margin-bottom: 30px;
    page-break-inside: avoid;

    .question {
      border-radius: 5px;
    }
    .printable-answer-placement {
      vertical-align: top;
    }

    .printable-pairs {
      .pair-wrapper-a,
      .pair-wrapper-b {
        display: inline-block;
        width: 33%;

        .answers {
          .pair-item {
            position: relative;
            padding: 10px;
            border: 1px solid #000000;
            margin-bottom: 10px;
            border-radius: 5px;

            &::after {
              position: absolute;
              top: 15px;
              width: 10px;
              height: 10px;
              border: 5px solid #000000; // background-color may be invisible on print, so we fill with border instead
              border-radius: 5px;
              content: '';
            }
          }
        }
      }

      .pair-wrapper-a {
        .answers .pair-item::after {
          right: -20px;
        }
      }

      .pair-wrapper-b {
        margin-left: 33%;

        .answers .pair-item::after {
          left: -20px;
        }
      }
    }

    .printable-input {
      height: 3em;
      padding: 6px 12px;
      border: 1px solid #000000;
    }

    .printable-checkbox {
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid black;
      margin-right: 10px;
      background-color: white;
    }

    .printable-radio-button {
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid black;
      margin-right: 10px;
      background-color: white;
      border-radius: 10px;
    }

    .answers {
      page-break-inside: avoid;
      .answer {
        padding-left: 0 !important;
        border-color: transparent !important;
      }
    }

    .sources .source .media-view-standalone {
      height: 150px;
    }
  }
}
