@import 'variables';

.linkedtasks-page {
  .end-message .panel-body {
    height: 200px;
  }
  .linked-element-add {
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    .icon {
      position: relative;
      top: 20%;
      text-align: center;
    }
    h3 {
      position: relative;
      top: 100px;
      display: inherit;
      text-align: center;
    }
  }
  .linked-element-list[dnd-list] {
    height: 325px;
    padding: 0px;
    margin: 0px;
  }
  .linked-element-list .dndPlaceholder {
    display: block;
    height: 325px;
    background-color: #f5f5f5;
    border-radius: 2px;
  }
  .linked-element-list li,
  .linked-element-add {
    display: block;
    width: 260px;
    height: 325px;
    margin-right: 25px;
    margin-bottom: 25px;
    float: left;
  }
  .linked-element-list li > .linked-element-item {
    width: 100%;
    height: 100%;
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    &:hover {
      -moz-box-shadow: 5px 5px 15px #ccc;
      -webkit-box-shadow: 5px 5px 15px #ccc;
      box-shadow: 5px 5px 15px #ccc;
      cursor: grab;
    }
    &:active {
      cursor: grabbing;
    }

    .linked-element-source-wrapper {
      position: relative;
      height: 200px;
      background-color: #dee3e9;
      .product-thumbnail {
        position: relative;
        height: 200px;
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .linked-element-product-type {
        position: relative;
        top: 40%;
        margin: 0 auto;
        text-align: center;
        transform: translateY(-50%);
      }
      .linked-element-source-control {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 40px;
        background: rgba(245, 245, 245, 0.8);
        text-align: right;
        .icon-container {
          padding: 5px 10px;
          div {
            display: inline-block;
            padding: 0px 5px;
          }
        }
      }
    }
    .linked-element-labels {
      display: block;
      height: 20px;
      margin: 8px 0px;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      &:first-of-type {
        @extend %text-elipsis;
      }
    }
    .linked-element-text-content {
      overflow: hidden;
      height: 83px;
      margin: 0;
    }
  }
}
