.education-package-list-page {
  .page-content-wrapper {
    margin-top: 30px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
    .page-content {
      margin: 0;
    }
    .education-package-control {
      .education-package-control-item {
        padding-right: 10px;
        padding-left: 10px;
        margin-bottom: 30px;
        .sort-field,
        .limit {
          select {
            height: 28px;
            padding-left: 5px;
            border: 1px solid $qe-secondary-background-color;
            background: $qe-input-background-color;
            color: $qe-tertiary-font-color;
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
          }
        }
        .sort-field {
          display: inline-block;
          select {
            width: 150px;
          }
        }
        .sorting-direction {
          display: inline-block;
          width: 30px;
          height: 28px;
          vertical-align: top;
          svg {
            height: 25px;
            margin: 1.5px 0px;
            fill: $qe-tertiary-font-color;
          }
        }

        .limit {
          text-align: right;
          select {
            width: 60px;
          }
        }
      }
    }
  }
}

.education-package-hidden-info-wrapper {
  padding: 20px 20px 0 20px;
}

.education-package-hidden-info-wrapper,
.education-package-info-wrapper {
  .education-package-info-title {
    margin: 0;
    margin-bottom: 10px;
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 500;
  }
  .education-package-info-content {
    margin-bottom: 5px;
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 400;
    text-align: justify;
  }
  .education-package-terms-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    .education-package-terms {
      display: flex;
      width: 18px;
      height: 18px;
      padding: 2px 1px;
      border: 2px solid $qe-tertiary-font-color;
      margin-right: 5px;
      border-radius: 2px;
      svg {
        width: auto;
        height: 100%;
        fill: $qe-tertiary-font-color;
      }
    }
    &.has-error {
      color: $qe-error-font-color;
      .education-package-terms {
        border-color: $qe-error-border-color;
        svg {
          fill: $qe-error-font-color;
        }
      }
    }
  }
}
