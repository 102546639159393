.media-view-standalone {
  position: relative;
  height: 100%;
  img.source-media {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    pointer-events: none;
  }
  iframe.source-media {
    width: 100%;
    height: 100%;
  }
  &.with-reference {
    img.source-media {
      max-height: calc(100% - 35px);
    }
    iframe.source-media {
      height: calc(100% - 35px);
    }
  }
  &.with-caption {
    img.source-media {
      max-height: calc(100% - 45px);
    }
    iframe.source-media {
      height: calc(100% - 45px);
    }
  }
  &.with-reference.with-caption {
    img.source-media {
      max-height: calc(100% - 70px);
    }
    iframe.source-media {
      height: calc(100% - 70px);
    }
  }
  .source-metadata {
    padding: 10px;
    text-align: center;
    > *:nth-child(2) {
      margin-top: 10px;
    }
    .source-caption,
    .source-reference {
      @extend %text-elipsis;
      display: block;
    }
    .source-caption {
      font-size: 16px;
      line-height: 25px;
    }
    .source-reference {
      font-size: 13px;
      font-style: italic;
      line-height: 15px;
      text-align: center;
    }
  }
  .copyrighted-source {
    position: absolute;
    top: 5px;
    right: 5px;
    color: $qe-error-font-color;
    font-size: 40px;
  }
}

.carousel {
  position: relative;
  max-width: 640px;
  height: 270px;
  @media (min-width: 992px) {
    height: 360px;
  }
}
.slide {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.slide-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  margin: auto;
}

.slide-next,
.slide-prev {
  position: absolute;
  top: 45%;
  color: #eee;
  cursor: pointer;
  opacity: 0.5;
  transform: translateY(-50%);
}

.slide-next:hover,
.slide-prev:hover {
  outline: none;
}

.slide-next:hover,
.slide-prev:hover {
  opacity: 0.9;
}

.slide-next {
  right: 10px;
}

.slide-prev {
  left: 10px;
}

iframe.slide-content {
  width: 100%;
  height: 100%;
}
