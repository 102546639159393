#print-it {
  position: fixed;
  top: 15px;
  left: 15px;
  &[right] {
    right: 15px;
    left: auto;
  }
  &[bottom] {
    top: auto;
    bottom: 15px;
  }
  .media-toggler {
    display: inline-block;
    margin-left: 15px;
    cursor: pointer;
  }
  @media print {
    display: none;
  }
}
