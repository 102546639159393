puzzle-printable {
  position: absolute;
  overflow: visible;
  margin-top: 15px;
  margin-left: 15px;

  .jigsaw-printable-content {
    position: absolute;
    top: 0;
    left: 0;

    svg {
      overflow: visible;
    }
  }
}
