$edu-primary-color: #4e7649;
$edu-primary-color-light: #dbe4db;
$edu-secodary-color: #fff;
$edu-danger-color: #c82026;
$edu-info-color: #337ab7;
$edu-warning-color-dark: #5a3e29;

$qe-primary-background-color: #fff;
$qe-secondary-background-color: #22466a;
$qe-tertiary-background-color: #1e3750;

$qe-input-border-color: #22466a;
$qe-input-background-color: #f6f6f6;
$qe-input-hover-background-color: #22466a;

$qe-primary-font-color: #000;
$qe-secondary-font-color: #f6f6f6;
$qe-tertiary-font-color: #22466a;

$qe-success-color: #50a019;
$qe-success-background-color: #cdf5d8;
$qe-error-background-color: #fde3dc;
$qe-error-border-color: #f04614;
$qe-error-font-color: #f04614;

$qe-source-decorator-color: #0078af;

// Product color schemes
$qe-product-background-color-1: #999999;
$qe-product-dim-background-color-1: rgba($qe-product-background-color-1, 0.2);
$qe-product-font-color-1: #1e3750;

$qe-product-background-color-2: #22466a;
$qe-product-dim-background-color-2: rgba($qe-product-background-color-2, 0.2);
$qe-product-font-color-2: #ffd200;

$qe-product-background-color-3: $qe-source-decorator-color;
$qe-product-dim-background-color-3: rgba($qe-product-background-color-3, 0.2);
$qe-product-font-color-3: #ffe155;

$qe-product-background-color-4: #8764b4;
$qe-product-dim-background-color-4: rgba($qe-product-background-color-4, 0.2);
$qe-product-font-color-4: #ffd200;

$qe-product-background-color-5: #cd55a5;
$qe-product-dim-background-color-5: rgba($qe-product-background-color-5, 0.2);
$qe-product-font-color-5: #ffe155;

$qe-product-background-color-6: #ffb4d2;
$qe-product-dim-background-color-6: rgba($qe-product-background-color-6, 0.2);
$qe-product-font-color-6: #8764b4;

$qe-product-background-color-7: #f04614;
$qe-product-dim-background-color-7: rgba($qe-product-background-color-7, 0.2);
$qe-product-font-color-7: #ffe155;

$qe-product-background-color-8: #f59100;
$qe-product-dim-background-color-8: rgba($qe-product-background-color-8, 0.2);
$qe-product-font-color-8: #22466a;

$qe-product-background-color-9: #ffd200;
$qe-product-dim-background-color-9: rgba($qe-product-background-color-9, 0.2);
$qe-product-font-color-9: $qe-source-decorator-color;

$qe-product-background-color-10: #50a019;
$qe-product-dim-background-color-10: rgba($qe-product-background-color-10, 0.2);
$qe-product-font-color-10: #ffe155;

$qe-product-background-color-11: #00781e;
$qe-product-dim-background-color-11: rgba($qe-product-background-color-11, 0.2);
$qe-product-font-color-11: #ffd200;

$qe-product-background-color-12: #55b4c8;
$qe-product-dim-background-color-12: rgba($qe-product-background-color-12, 0.2);
$qe-product-font-color-12: #ffe155;
