@import 'variables';
.timeline-page {
  ul.slide-event-list {
    position: relative;
    padding: 0px;
    margin: 5px 0px 30px 0px;
    list-style-type: none;

    > li {
      display: flex;
      height: 46px;
      padding: 12px;
      border-width: 1px;
      border-style: solid;
      margin-bottom: 10px;
      border-radius: 2px;
      font-size: 14px;

      .title {
        @extend %text-elipsis;
        flex: 1;
        color: $qe-tertiary-font-color;
        font-size: 15px;
        font-weight: bold;
      }
      .event-functions {
        display: flex;
        height: 22px;
        float: right;
        .icon {
          margin-left: 10px;
        }
      }
      .due-date {
        display: inline-block;
        width: 120px;
        color: #6d6b6b;
      }
    }
    > li:focus {
      outline: none;
    }
  }
  .sorting {
    .icon {
      &.date-order {
        margin-left: 5px;
      }
      &.name-order {
        margin-left: 95px;
      }
      &:not(.selected) {
        opacity: 0.5;
      }
    }
  }
}

#timeline {
  width: 100%;
  height: calc(100% - 60px);
}

.timeline-container {
  position: relative;
  margin-top: 20px;
}

.timeline-controls {
  button.btn {
    display: inline-block;
  }
}

// Common style attributes
timeline-slide {
  display: block;
  border: 1px solid black;

  & > section {
    height: 100%;

    & > .timeline-content {
      position: relative;
      display: block;
      overflow: auto;
      height: 100%;
      background-color: rgb(250, 250, 250);
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .slide-section-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;

    &.media-block {
      border-left: 1px solid black;

      media-view {
        display: block;
        width: 100%;
        height: 100%;

        & > * {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
    }
  }
}

/* Slide viewer */
timeline-slide {
  section.slide {
    height: 100%;

    .media {
      height: 100%;
      padding: 20px 10px;
    }

    .description {
      .title {
        padding-bottom: 10px;
        border-bottom: 1px solid black;
        font-size: 20px;
        text-align: left;
      }

      .text {
        padding-top: 10px;
        text-align: left;
      }
    }
  }
}
