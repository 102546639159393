@import 'variables';

.footer-wrapper {
  background: $qe-tertiary-background-color;
  .footer-wrapper-inner {
    height: 40px;
    padding: 11px 20px;
    color: $qe-source-decorator-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 300;
    line-height: 18px;
    text-align: center;
  }
  a {
    text-decoration: underline;
    &:hover,
    &:focus {
      color: $qe-source-decorator-color;
    }
  }
}
