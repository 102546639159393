.timeline-viewer {
  height: auto;

  .timeline-content {
    margin-right: auto;
    margin-left: auto;
    timeline {
      position: relative;
    }
  }
}

.tl-slide {
  .tl-slide-scrollable-container {
    width: 100%;
  }
  .tl-slide-content-container .tl-slide-content {
    width: 100% !important;
    .tl-media {
      display: table-cell;
      width: 40%;
      float: none;
      vertical-align: middle;
      .tl-media-loaderror {
        p {
          color: $qe-error-font-color;
        }
        .fa-copyright {
          margin-left: 5px;
        }
      }
    }
    .tl-text {
      width: 60%;
    }
  }
  &.tl-slide-text-only .tl-slide-content-container .tl-slide-content .tl-text {
    width: auto;
  }
}

.tl-media-content-container {
  display: flex;
  flex-direction: column;
  .tl-media-item {
    order: 1;
  }
  .tl-icon-europeana:before {
    content: 'europeana';
    font-family: 'Times New Roman';
  }
  .tl-caption {
    @extend %text-elipsis;
    order: 2;
    font-size: 16px;
    font-style: normal;
    span {
      display: block;
    }
  }
  .tl-credit {
    @extend %text-elipsis;
    order: 3;
    padding-top: 10px;
    font-size: 13px;
    font-style: italic;
    text-align: center;
    a {
      display: inline-block;
    }
  }
}

@media (min-width: 992px) {
  .timeline-viewer {
    .timeline-content {
      padding: 0px 50px;
    }
  }
}

.tl-skinny .tl-slide .tl-slide-content-container .tl-slide-content .tl-media {
  padding-top: 0;
  border-top: none;
  .tl-media-content-container {
    padding: 10px;
    .tl-caption,
    .tl-credit {
      font-size: 12px;
    }
    .tl-caption {
      margin-top: 10px;
    }
  }
}

.tl-slidenav-previous {
  margin-left: 25px;
}

.tl-slidenav-next {
  margin-right: 25px;
}

.tl-timemarker
  .tl-timemarker-content-container
  .tl-timemarker-content
  .tl-timemarker-media-container
  [class^='tl-icon-'] {
  display: none;
}

.tl-layout-landscape.tl-mobile {
  .tl-slidenav-previous:hover .tl-slidenav-icon {
    margin-left: 5px;
  }
  .tl-slidenav-next:hover {
    right: 100px;
    .tl-slidenav-icon {
      margin-left: 71px;
    }
  }
}
