.product-selector-modal {
  .product-selector-filter {
    margin-bottom: 15px;
  }
  .product-selector-wrapper {
    display: flex;
    align-items: center;
    .product-paginator {
      flex: 1;
      color: $qe-tertiary-font-color;
      &:first-child {
        margin-left: 15px;
        text-align: left;
      }
      &:last-child {
        margin-right: 15px;
        text-align: right;
      }
      i.fa {
        cursor: pointer;
        &.disabled {
          color: #cdd2d7;
          cursor: not-allowed;
        }
      }
    }
    .product-selector {
      flex: 11;
      padding: 0;
      .product-thumbnail-wrapper {
        height: 340px;
        margin-bottom: 0;
        .product-thumbnail-image {
          height: 175px;
        }
      }
    }
  }
  .filter-field select {
    width: 150px;
    height: 28px;
    padding-left: 5px;
    border: 1px solid #22466a;
    background: #f6f6f6;
    color: #22466a;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
  }
  .entity-thumbnail-wrapper .entity-thumbnail-metadata-wrapper {
    height: 140px;
  }
  .product-thumbnail-wrapper {
    .selected-product {
      position: absolute;
      z-index: 100;
      top: 5px;
      right: 15px;
      display: block;
      width: 26px;
      height: 26px;
      padding: 4px;
      border-color: $qe-success-color;
      background: $qe-success-color;
      border-radius: 15px;
      color: white;
      font-size: 18px;
      line-height: 18px;
    }
    .product-thumbnail-metadata-wrapper {
      .title {
        max-height: 60px;
      }
      .metadata {
        height: 40px;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        .product-type-icon {
          height: 100%;
          margin-right: 10px;
          float: left;
          svg {
            width: auto;
            height: 100%;
          }
        }
        .product-type {
          height: 100%;
        }
      }
    }
  }

  .linkedtask-text-editor {
    .panel-body {
      height: 170px;
    }
  }
}
