.linked-tasks-viewer .product-viewer {
  .product-viewer-content {
    padding-bottom: 75px;
    &.end-message,
    &.before-product {
      position: relative;
      .text-content {
        width: 50%;
        margin: 0 auto 20px;
        &:before,
        &:after {
          display: table;
          content: ' ';
        }
        &:after {
          clear: both;
        }
      }
    }
  }

  .next-task-summary {
    padding: 20px;
  }

  .next-product {
    position: relative;
    .button-wrapper {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 15px;
      text-align: center;
    }
  }
}

@media (min-width: 992px) {
  .linked-tasks-viewer .product-viewer .next-product {
    padding-right: 30px;
    padding-left: 30px;
    margin-left: -40px;
    .button-wrapper {
      text-align: right;
    }
  }
}
