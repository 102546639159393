.puzzle-page {
  .puzzle-page-row {
    padding: 5px;
    margin: 5px;
  }

  .puzzle-form {
    padding-bottom: 15px;
  }

  .puzzle-preview-wrapper {
    position: relative;
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 20px;

    media-preview {
      img {
        max-width: 100%;
        max-height: 730px;
      }
    }

    #puzzle-svg-preview {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      * {
        pointer-events: none;
      }

      svg {
        overflow: visible;
      }
    }
  }
}
