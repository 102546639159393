.timeline-viewer.printable {
  background: #fff;
  .timeline-content {
    display: table;
    width: 70%;
    margin: 20px auto;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    page-break-after: always;
    .timeline-source-wrapper {
      width: 500px;
      float: left;
      .slide-source {
        position: relative;
        display: flex;
        width: auto;
        max-width: 100%;
        max-height: 490px;
        margin: auto;
      }
    }
    .slide-info {
      display: table-cell;
      width: 50%;
      min-width: 120px;
      max-width: 50%;
      color: #737373;
      text-align: left;
      vertical-align: middle;
      .due-date {
        margin: 0 0 3px;
        color: #b3b3b3;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 15px;
      }
    }
  }
}
