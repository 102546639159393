@import 'variables';
.message-modal {
  .modal-header {
    background: $qe-error-font-color;
    color: $qe-error-background-color;
  }
  .modal-body {
    color: $qe-error-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 400;
  }
  .modal-footer {
    padding: 0px 20px 20px 20px;
    border-top-width: 0;
    .btn-primary {
      border-color: $qe-error-border-color;
      background: $qe-error-font-color;
      color: $qe-error-background-color;
      &:hover,
      &:focus,
      &.focus,
      &:active,
      &.active,
      &:active:focus,
      &.active:focus,
      &:active:focus,
      &.active:focus {
        border-color: $qe-error-border-color;
        background: $qe-error-font-color;
        color: $qe-error-background-color;
      }
    }
    .btn-default {
      border-color: $qe-success-color;
      margin-right: 10px;
      background: $qe-success-color;
      color: $qe-secondary-font-color;
      &:hover,
      &:focus,
      &.focus,
      &:active,
      &.active,
      &:active:focus,
      &.active:focus,
      &:active:focus,
      &.active:focus {
        border-color: $qe-success-color;
        background: $qe-success-color;
        color: $qe-secondary-font-color;
      }
    }
  }
}
