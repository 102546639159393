#loading-bar-spinner {
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($edu-info-color, 0.15);
  transition: 150ms linear opacity;
  .spinner-icon {
    width: 60px;
    height: 60px;
    border-width: 5px;
    border-top-color: $edu-info-color;
    border-left-color: $edu-info-color;
  }
}
