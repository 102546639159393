.text-source-selector-modal {
  .modal-header {
    background: $qe-source-decorator-color;
  }
  .modal-body {
    padding: 0 15px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    .source-wrapper,
    .source-text-wrapper {
      height: 75vh;
      padding: 15px;
      overflow-y: auto;
    }
    .source-wrapper {
      border-right: 1px solid $qe-source-decorator-color;
      .source {
        padding: 3px;
        border: 1px solid $qe-source-decorator-color;
        margin: 10px 0px;
        background-color: rgba($qe-source-decorator-color, 0.4);
        color: $qe-source-decorator-color;
        text-align: initial;
        &:focus,
        &:hover,
        &.selected {
          background: $qe-source-decorator-color;
          .title {
            color: white;
          }
        }
        media-preview {
          overflow: hidden;
          height: 200px;
          background-color: #fff;
          div.text {
            max-height: 200px;
            padding: 5px;
            background: -webkit-linear-gradient($qe-source-decorator-color 70%, #fff);
            -webkit-background-clip: text;
            background-color: white;
            -webkit-text-fill-color: transparent;
          }
        }
        .title {
          @extend %text-elipsis;
          display: block;
          padding: 7px;
        }
      }
    }
    .source-text-wrapper {
      .source-text {
        text-align: initial;
      }
    }
  }
}
