@import 'variables';

.slideshow-page {
  .slide-add {
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    .icon {
      position: relative;
      top: 20%;
      text-align: center;
    }
    h3 {
      position: relative;
      top: 100px;
      display: block;
      text-align: center;
    }
  }
  .slide-list[dnd-list] {
    height: 325px;
    padding: 0px;
    margin: 0px;
  }
  .slide-list .dndPlaceholder {
    display: block;
    height: 325px;
    background-color: #f5f5f5;
    border-radius: 2px;
  }
  .slide-list li,
  .slide-add {
    display: block;
    width: 275px;
    height: 325px;
    margin-right: 12px;
    margin-bottom: 12px;
    float: left;
  }
  .slide-list li > .slide-item {
    width: 100%;
    height: 100%;
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    &:hover {
      -moz-box-shadow: 5px 5px 15px #ccc;
      -webkit-box-shadow: 5px 5px 15px #ccc;
      box-shadow: 5px 5px 15px #ccc;
      cursor: grab;
    }
    &:active {
      cursor: grabbing;
    }
    .slide-source-wrapper {
      position: relative;
      height: 200px;
      background: black;
      .slide-source-control {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 40px;
        background: rgba(245, 245, 245, 0.6);
        text-align: right;
        .icon-container {
          padding: 5px 10px;
          div {
            display: inline-block;
            padding: 0px 5px;
          }
        }
      }
    }
    .slide-title {
      @extend %text-elipsis;
      height: 20px;
      margin: 5px 0px;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
    }
    .slide-description {
      overflow: hidden;
      height: 83px;
      margin: 0;
    }
  }
}

.slide-modal {
  .position-selector {
    label:not(.btn) {
      color: $qe-tertiary-font-color;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 700;
      line-height: 30px;
    }
    label.btn {
      min-width: 50px;
      color: white;
    }
  }
  .slide-title {
    input {
      border: none;
      -moz-box-shadow: none;
      box-shadow: none;
      -webkit-boxshadow: none;
      font-size: 30px;
    }
    &.has-error {
      input {
        border: 1px solid #c82026;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      }
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #c82026;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: #c82026;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: #c82026;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: #c82026;
      }
    }
  }
  .slide-description {
    border: none;
    border-left: 5px solid #bbb;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    resize: vertical;
  }
  .slide-source {
    display: flex;
    .left {
      margin-right: 15px;
    }
    .right {
      margin-left: 15px;
    }
    .slide-text-wrapper {
      flex: 1;
    }
    .slide-source-wrapper {
      min-width: 0;
      flex: 1;
      .slide-source-preview {
        border: 1px solid #bbb;
      }
      iframe.slide-source-preview {
        height: 430px;
      }
    }
  }
}
