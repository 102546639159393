.btn {
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:focus,
  &.active:focus {
    outline: none;
  }
}

.btn {
  min-width: 100px;
  height: 26px;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 14px;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  &:hover {
    opacity: 0.8;
  }
}

.btn-normal {
  min-width: auto;
}

.btn-lg {
  min-width: 150px;
  height: 36px;
  padding: 8px 16px;
  border-radius: 18px;
  font-family: 'Varela Round', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}

.btn-primary {
  border-color: $qe-success-color;
  background: $qe-success-color;
  color: $qe-secondary-font-color;
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:focus,
  &.active:focus {
    border-color: $qe-success-color;
    background: $qe-success-color;
    color: $qe-secondary-font-color;
  }
}

.btn-secondary {
  border-color: #22466a;
  background: #22466a;
  color: $qe-secondary-font-color;
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:focus,
  &.active:focus {
    border-color: #22466a;
    background: #22466a;
    color: $qe-secondary-font-color;
  }
}

.btn-tertiary {
  border-color: #22466a;
  background: #fff;
  color: #22466a;
  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:active:focus,
  &.active:focus {
    border-color: #22466a;
    background: #fff;
    color: #22466a;
  }
}
