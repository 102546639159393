@import 'variables';
.logo-wrapper {
  flex: 1;
  .logo-wrapper-inner {
    display: inline-flex;
    padding: 0 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    .logo {
      height: 44px;
    }
    .divider {
      border-right: 1px solid #a6b3c1;
      margin: 0 10px;
    }
    .title {
      padding: 0;
      font-size: 30.4px;
      line-height: 44px;
    }
  }
}

header {
  background: $qe-secondary-background-color; // For browsers that do not support gradients
  background: -webkit-linear-gradient(
    linear,
    left top,
    left bottom,
    $qe-secondary-background-color,
    $qe-tertiary-background-color
  ); // For Safari 5.1 to 6.0
  background: -o-linear-gradient(
    top,
    $qe-secondary-background-color,
    $qe-tertiary-background-color
  ); // For Opera 11.1 to 12.0
  background: -moz-linear-gradient(
    top,
    $qe-secondary-background-color,
    $qe-tertiary-background-color
  ); // For Firefox 3.6 to 15 */
  background: linear-gradient(
    to bottom,
    $qe-secondary-background-color,
    $qe-tertiary-background-color
  ); //Standard syntax */
  //color: $qe-primary-background-color;
  .header-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0px;
    .logo-wrapper,
    .title-wrapper,
    .menu-wrapper {
      flex: 1 1 100%;
      text-align: center;
      color: $qe-secondary-font-color;
      font-family: 'Varela Round', sans-serif;
    }
    .title-wrapper {
      .title {
        padding: 0;
        margin: 0;
        font-size: 22px;
        line-height: 44px;
      }
    }
    .menu-wrapper {
      height: 100%;
      flex: 1;
      .divider {
        display: none;
      }
      .icon-wrapper {
        display: inline-block;
        margin: 0 10px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        .icon {
          height: 24px;
          margin-bottom: 7px;
          text-align: center;
          svg {
            width: 25px;
            height: 100%;
            fill: $qe-secondary-font-color;
          }
        }
        .icon-label {
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          font-weight: 300;
          line-height: 13px;
          text-align: center;
        }
      }
    }
  }
}

.language-dropdown {
  padding: 0;
  margin: 15px 0 0;
  border-radius: 2px;
  .language-dropdown-item {
    padding: 10px 0 10px 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    .icon {
      svg {
        width: auto;
        height: 12px;
      }
    }
    &:hover {
      background: $qe-secondary-background-color;
      color: $qe-secondary-font-color;
    }
  }
}

@media (min-width: 992px) {
  header .header-wrapper {
    height: 60px;
    .logo-wrapper,
    .title-wrapper,
    .menu-wrapper {
      flex-basis: 0%;
    }
    .logo-wrapper {
      text-align: left;
      .logo-wrapper-inner .divider {
        margin: 0 20px;
      }
    }
    .menu-wrapper {
      text-align: right;
      .divider {
        display: inline-block;
        height: 100%;
        border-right: 1px solid #a6b3c1;
        margin: 0 10px;
      }
    }
  }
}
