.quiz-form {
  .quiz-ordered-radio-wrapper {
    display: flex;
    align-items: center;
    margin: 5px 30px 5px 0;
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    .quiz-ordered-radio {
      display: flex;
      width: 18px;
      height: 18px;
      padding: 4px;
      border-width: 1px;
      border-style: solid;
      margin-right: 5px;
      border-radius: 50%;
      .checked-circle {
        width: 8px;
        height: 8px;
        background: $qe-tertiary-font-color;
        border-radius: 50%;
      }
    }
  }
}

// .quiz-questions.has-error {
// }
.quiz-questions {
  .quiz-questions-empty {
    padding: 15px 25px;
    border-width: 1px;
    border-style: solid;
    border-radius: 1px;
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }
  padding: 10px 0px;
  border-radius: 2px;
  ul[dnd-list] {
    position: relative;
    padding: 0px;
    margin: 0;
    list-style-type: none;
    .dndDraggingSource {
      display: none;
    }
    .dndPlaceholder {
      display: block;
      height: 46px;
      border-color: $qe-secondary-background-color;
      margin-bottom: 10px;
      background-color: #f6f6f6;
    }
    > li {
      height: 46px;
      padding: 12px;
      border-width: 1px;
      border-style: solid;
      margin-bottom: 10px;
      background-color: #fff;
      border-radius: 2px;
      font-size: 14px;
      dnd-nodrag {
        display: flex;
        height: 100%;
        .question-drag {
          i[dnd-handle] {
            color: $qe-tertiary-font-color;
            cursor: move;
          }
        }
        .question-content {
          @extend %text-elipsis;
          width: 100%;
          margin: 0px 10px;
          color: $qe-tertiary-font-color;
          font-family: 'Montserrat', sans-serif;
          font-size: 15px;
          font-weight: 500;
        }
        .question-function {
          display: flex;
          .question-function-item {
            margin-left: 10px;
            svg {
              width: auto;
              height: 100%;
              fill: $qe-tertiary-font-color;
            }
          }
        }
      }
    }
  }
}

.question-form {
  .quiz-source-selector > div,
  .quiz-answer-list > div {
    margin-bottom: 5px;
  }
  .question-sources-empty {
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 400;
  }
  .question-source-list {
    padding: 0;
    margin: 0;
    list-style: none;
    .question-source-wrapper {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 50px;
      margin: 0px 5px;
      background-color: black;
      text-align: center;
      .question-source {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .overlay {
        position: absolute;
        display: none;
        width: 100%;
        height: 100%;
        .overlay-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          cursor: pointer;
          transform: translate(-50%, -50%);
        }
      }
    }
    .question-source-wrapper:hover .overlay {
      display: block;
      background-color: rgba(0, 0, 0, 0.75);
    }
  }
  ul[dnd-list] {
    position: relative;
    padding: 0px;
    margin: 0;
    list-style-type: none;
    .dndDraggingSource {
      display: none;
    }
    .dndPlaceholder {
      display: block;
      height: 37px;
      border-color: $qe-secondary-background-color;
      margin-bottom: 10px;
      background-color: #f6f6f6;
    }
    > li {
      height: 46px;
      padding: 8px 12px;
      border-width: 1px;
      border-style: solid;
      margin-bottom: 10px;
      background-color: #fff;
      border-radius: 1px;
      color: $qe-tertiary-font-color;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 400;
      dnd-nodrag {
        display: block;
        i[dnd-handle] {
          color: $qe-tertiary-font-color;
          cursor: move;
        }
        .answer-content {
          margin: 0px 5px;
        }
        .btn {
          min-width: initial;
        }
      }
    }
  }
  .answer-form {
    .answer-item {
      display: flex;
      align-items: center;
      .radio,
      .checkbox {
        margin: 0px;
        input {
          position: relative;
          top: 2px;
          margin: 0;
        }
      }
      .drag-n-drop-handle {
        flex: 1;
        line-height: 28px;
      }
      .answer-wrapper {
        display: flex;
        flex: 30;
        .choice-answer-content {
          flex: 16;
        }
        .choice-answer-function {
          flex: 3;
          line-height: 28px;
        }
      }
      .pair-answer-input {
        flex: 9;
      }
      .answer-delete-button {
        height: 22px;
        flex: 1;
        svg {
          width: auto;
          height: 100%;
          fill: $qe-tertiary-font-color;
        }
        &[disabled] {
          cursor: not-allowed;
        }
      }
    }
  }
}
