@import 'variables';
.login-page {
  position: relative;
  height: 100%;
  background: $qe-secondary-background-color; // For browsers that do not support gradients
  background: -webkit-linear-gradient(
    linear,
    left top,
    left bottom,
    $qe-secondary-background-color,
    $qe-tertiary-background-color
  ); // For Safari 5.1 to 6.0
  background: -o-linear-gradient(
    top,
    $qe-secondary-background-color,
    $qe-tertiary-background-color
  ); // For Opera 11.1 to 12.0
  background: -moz-linear-gradient(
    top,
    $qe-secondary-background-color,
    $qe-tertiary-background-color
  ); // For Firefox 3.6 to 15 */
  background: linear-gradient(
    to bottom,
    $qe-secondary-background-color,
    $qe-tertiary-background-color
  ); //Standard syntax */
  .header {
    background: initial;
    color: $qe-secondary-font-color;
    text-align: center;
    .logo-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-top: 60px;
      .company-logo-wrapper,
      .project-logo {
        padding: 0 15px;
      }
      .company-logo-wrapper {
        border-right: 1px solid $qe-secondary-font-color;
        .company-logo {
          max-height: 70px;
        }
      }
      .project-logo {
        margin: 0;
        color: $qe-secondary-font-color;
        font-family: 'Varela Round', sans-serif;
        font-size: 48pt;
        line-height: 56px;
      }
    }
  }
  .login-page-content {
    position: absolute;
    top: 30%;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    .illustration-column,
    .login-form-column {
      flex: 1;
      align-self: center;
      padding: 0px 25px;
    }
    .illustration-column {
      .illustration {
        float: right;
      }
    }
    .login-form-column {
      .login-form-wrapper {
        max-width: 400px;
        padding: 30px;
        background: $qe-primary-background-color;
        -webkit-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
        .title {
          margin: 0;
          margin-bottom: 20px;
          color: $qe-tertiary-font-color;
          font-family: 'Varela Round', sans-serif;
          font-size: 24pt;
          text-align: center;
        }
        .has-error {
          .control-label {
            margin-bottom: 15px;
            font-family: 'Montserrat', sans-serif;
            font-size: 12pt;
          }
        }
        .form-group {
          margin-bottom: 25px;
          label {
            color: $qe-tertiary-font-color;
            font-family: 'Montserrat', sans-serif;
            font-size: 14pt;
          }
          input {
            height: 36px;
            border: 1pt solid $qe-input-border-color;
            background: $qe-input-background-color;
            border-radius: 2px;
          }
        }
        .login-button-wrapper {
          padding-top: 5px;
          text-align: center;

          i {
            margin-right: 5px;
          }
        }
      }
      .language-selector-wrapper {
        max-width: 400px;
        padding: 15px 0;
        cursor: pointer;
        text-align: center;
        .language-selector-button {
          color: $qe-source-decorator-color;
          font-family: 'Montserrat', sans-serif;
          font-size: 15px;
          font-weight: 300;
          line-height: 18px;
        }
        .language-dropdown {
          padding: 0;
          .language-dropdown-item {
            padding: 10px 0 10px 10px;
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            .icon {
              svg {
                width: auto;
                height: 12px;
              }
            }
            &:hover {
              background: $qe-secondary-background-color;
              color: $qe-secondary-font-color;
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      .illustration-column {
        display: none !important;
      }
      .login-form-column {
        .login-form-wrapper,
        .language-selector-wrapper {
          margin: 0 auto;
        }
      }
    }
    @media (min-width: 768px) and (max-width: 991px) {
      .illustration-column,
      .login-form-column {
        padding: 0px 5px;
      }
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      .illustration-column,
      .login-form-column {
        padding: 0px 15px;
      }
    }
    @media screen and (max-height: 700px) {
      .illustration-column {
        .illustration {
          height: 300px;
        }
      }
      .login-form-column {
        height: 300px;
        .login-form-wrapper {
          padding: 10px 30px;
        }
      }
    }
    p {
      color: $qe-tertiary-font-color;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      a,
      a:hover {
        color: $qe-source-decorator-color;
      }
    }
  }
  footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
