@import 'variables';
.admin-package-subheader {
  .tab-selector {
    .tab-item {
      width: auto;
      padding: 0px 10px;
      a {
        color: $edu-secodary-color;
        text-decoration: none;
        &:hover,
        &:focus {
          color: $edu-secodary-color;
          text-decoration: none;
        }
      }
      &:hover,
      &:focus,
      &.active {
        a {
          color: $qe-tertiary-font-color;
          &:hover,
          &:focus {
            color: $qe-tertiary-font-color;
          }
        }
      }
    }
  }
}

.contributor-type-checkbox {
  display: flex;
  width: 18px;
  height: 18px;
  padding: 4px 3px;
  border: 1px solid $qe-tertiary-font-color;
  border-radius: 2px;
  float: right;
  svg {
    width: auto;
    height: 100%;
    fill: $qe-tertiary-font-color;
  }
}

.contributorTypeFilter {
  max-width: 240px;
}

.pagination-contributor-type {
  text-align: center;
}

.sort-contributor-select {
  padding-right: 5px;
  select {
    height: 28px;
    padding-left: 5px;
    border: 1px solid $qe-secondary-background-color;
    background: $qe-input-background-color;
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
  }
}

.sorting-direction {
  display: inline-block;
  width: 30px;
  height: 28px;
  svg {
    height: 25px;
    margin: 1.5px 0px;
    fill: $qe-tertiary-font-color;
  }
}

.sort-contributor {
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
}

.contributor-type-column-title {
  width: 260px;
}

.selectable-title {
  text-align: right;
}

.admin-package-page {
  .content-wrapper {
    .contributor-type-search-bar-wrapper {
      display: flex;
      .form-control {
        margin-right: 10px;
      }
      .search-bar {
        width: auto;
        flex: 1;
        margin-right: 20px;
      }
    }
    .contributor-filter-input {
      padding-left: 0px;
      margin-bottom: 20px;
    }

    .form-control {
      padding: 4px 10px;
      border: 1px solid $qe-input-border-color;
      background: $qe-input-background-color;
      border-radius: 1px;
      color: $qe-tertiary-font-color;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 500;
      option {
        padding: 3px 20px;
        background: $qe-input-background-color;
        color: $qe-tertiary-font-color;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 400;
        &:hover {
          background: $qe-input-hover-background-color;
          color: $qe-secondary-font-color;
        }
      }
    }
    .settings-treeview-wrapper {
      padding: 10px;
      border: 1px solid $qe-input-border-color;
      margin-top: 20px;
      margin-bottom: 20px;
      .admin-control-icon {
        margin-right: 4px;
        line-height: 15px;
        &:last-child {
          margin-right: 0px;
        }
        svg {
          width: 15px;
          height: 15px;
          fill: #22466a;
        }
      }
    }

    .institution-admin-page,
    .contributor-admin-page {
      table {
        th {
          background-color: $qe-secondary-background-color;
          color: white;
        }
        tr {
          &:nth-of-type(odd) {
            background-color: #d7dce1;
          }
          &:nth-of-type(even) {
            background-color: rgba(215, 220, 225, 0.3);
          }
          td {
            color: #22466a;
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            font-weight: 500;
            &.institution-id,
            &.institution-name {
              font-weight: 700;
            }
            .institution-control-icon {
              margin-right: 6px;
              svg {
                width: 13px;
                height: 13px;
                fill: #22466a;
              }
              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }
        .operations {
          width: 100px;
        }
      }
    }
  }
}
