.wordfind-viewer {
  height: auto;
  padding: 50px;
}

wordfind-printable,
.wordfind-viewer {
  .wordfind-content {
    padding-top: 15px;
    margin-right: auto;
    margin-left: auto;

    .word-search-wrapper,
    .fulltext-wrapper {
      margin-bottom: 15px;
    }

    .word-search-wrapper:after {
      display: table;
      clear: both;
      content: ' ';
    }

    .word-search-wrapper {
      .word-search-puzzle {
        border: 1px solid #999;
        margin: 0 auto;

        td {
          -moz-user-select: -moz-none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;

          span {
            display: table-cell;
            width: 30px;
            height: 30px;
            cursor: pointer;
            text-align: center;
            text-transform: uppercase;
            user-select: none;
            vertical-align: middle;
          }
        }

        .puzzle-solved td {
          background-color: #eee;
        }
      }

      .words {
        padding: 0;
        list-style: none;

        .word {
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 10px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .word.found {
          color: #b3b3b3;
          font-weight: normal;
          text-decoration: line-through;
        }

        .word:last-child {
          margin: 0;
        }
      }
    }

    .fulltext-wrapper {
      .content-box {
        text-align: justify;
        white-space: pre-wrap;
      }

      .found-word {
        font-weight: bold;
      }
    }

    .function-wrapper {
      text-align: center;
    }

    media-gallery-standalone {
      .sources.carousel {
        margin: 30px auto 0px;
      }
    }
  }
}
@media (min-width: 768px) {
  .wordfind-viewer {
    .wordfind-content {
      width: 690px;
      padding-top: 40px;
    }

    .wordfind-content {
      .word-search-wrapper {
        display: flex;
        .words {
          flex: 1;
          order: 2;
          margin-left: 25px;
          text-align: center;
          .word {
            display: list-item;
            margin-right: auto;
          }
        }
      }
    }
  }
}
