@import 'variables';
.product {
  .product-content-wrapper {
    padding-top: 15px;
    padding-right: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
    .product-menu {
      margin-bottom: 15px;
      .publication-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .publication-icon {
          width: 28px;
          height: 28px;
          padding: 6px;
          margin-right: 15px;
          background: $qe-success-color;
          border-radius: 5px;
          float: left;
          svg {
            width: 100%;
            height: 100%;
            fill: #f6f6f6;
          }
        }
        .publication-status {
          margin-right: 5px;
        }
        .publication-status,
        .publication-time {
          display: inline-block;
          color: $qe-success-color;
          font-family: 'Montserrat', sans-serif;
          font-size: 15px;
          font-weight: 500;
          line-height: 28px;
        }
        .publication-details {
          margin-right: 15px;
          margin-bottom: 15px;
        }
        .publication-time {
          font-weight: 400;
        }
        .publish-functions {
          flex-basis: 100%;
          margin-bottom: 15px;
        }
      }
    }
  }
  .em-title {
    display: inline-block;
    margin: 0;
    margin-bottom: 15px;
    color: $qe-tertiary-font-color;
    font-family: 'Varela Round', sans-serif;
    font-size: 20px;
  }
  .sources-empty {
    padding: 15px 25px;
    border-width: 1px;
    border-style: solid;
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
  }
  .source-selector {
    margin-bottom: 5px;
  }
  .source-list {
    padding: 0;
    margin: 0;
    list-style: none;
    .source-wrapper {
      position: relative;
      display: inline-block;
      width: 50px;
      height: 50px;
      border-width: 1px;
      border-style: solid;
      margin: 0px 5px;
      text-align: center;
      .source {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      media-preview {
        img.cover {
          width: auto;
          max-width: 100%;
          height: 100%;
          max-height: 100%;
          background-position: center;
        }
        .copyrighted-source {
          top: 0;
          right: 0;
          font-size: 1em;
        }
      }
      .overlay {
        position: absolute;
        display: none;
        width: 100%;
        height: 100%;
        .overlay-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          min-width: initial;
          cursor: pointer;
          transform: translate(-50%, -50%);
        }
      }
    }
    .source-wrapper:hover {
      .source {
        filter: blur(1px);
      }
      .overlay {
        display: block;
      }
    }
  }
  .remove-left-padding {
    padding-left: 0px;
  }
  .remove-right-padding {
    padding-right: 0px;
  }
  .sorting {
    .sorting-title {
      padding: 7px 0px;
      margin: 0px;
      color: $qe-tertiary-font-color;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 700;
      line-height: 16px;
    }
    .icon-container {
      display: flex;
    }
    .icon {
      width: 30px;
      height: 30px;
      padding: 5px;
      margin-left: 10px;
    }
  }
  .icon {
    svg {
      width: auto;
      height: 100%;
      fill: $qe-tertiary-font-color;
    }
  }
  @mixin elementsByScheme($primary, $primaryDim, $secondary) {
    .scheme-bg {
      background-color: $primary;
      color: $secondary;
    }
    .scheme-bg-inverse {
      background-color: $secondary;
      color: $primary;
    }
    .scheme-bg-dim {
      background-color: $primaryDim;
    }
    .scheme-border {
      border-color: $primary;
    }
    .scheme-border-inverse {
      border-color: $secondary;
    }
    .scheme-text {
      color: $primary;
    }
    .btn {
      &.scheme-primary,
      .scheme-primary:active,
      .scheme-primary:focus,
      .scheme-primary:active:focus {
        border-color: $primary;
        background-color: $primary;
        color: $secondary;
      }
      &.scheme-tertiary {
        border-color: $primary;
        background-color: white;
        color: $primary;
      }
    }
    .slide-next,
    .slide-prev,
    .slide-counter {
      color: $secondary;
    }
    input,
    select {
      &.scheme {
        border-color: $primary;
        background-color: $primaryDim;
      }
    }
    .source-list .source-wrapper {
      border-color: $primary;
      background-color: $primaryDim;
    }
    .note-btn-group {
      .btn {
        border-color: $primary;
        background-color: #fff;
      }
      .btn:not(.note-current-color-button) {
        color: $primary;
        &.active {
          border-color: $primary;
          background-color: $primary;
          color: $secondary;
        }
      }
    }
    .note-editor {
      border-color: $primary;
      &.note-frame .note-editing-area .note-editable {
        color: $primary;
      }
    }
    .scheme-svg svg {
      fill: $primary;
    }
    .scheme-svg-inverse svg {
      fill: $secondary;
    }
    .product-thumbnail-metadata-wrapper .title:after {
      background: $primary;
    }

    .tl-caption {
      color: $primary;
    }

    .tl-credit {
      color: $primary;
    }

    .tl-text-content {
      p {
        span {
          color: $primary;
        }
      }
    }

    .summary-wrapper {
      .info-box {
        border-color: $primary;
        background: #fff;
        color: $primary;
        .header {
          background: $primary;
          color: $secondary;
        }
      }
      .btn {
        border-color: $primary;
        background-color: $primary;
        color: $secondary;
      }
    }

    .product-summary {
      .info-box {
        .header {
          background: $primary;
          color: $secondary;
        }
      }
    }

    .text-center {
      .btn {
        border-color: $primary;
        background-color: $primary;
        color: $secondary;
      }
    }
  }
  &.scheme_1,
  &.scheme_1.nested-scheme {
    @include elementsByScheme(
      $qe-product-background-color-1,
      $qe-product-dim-background-color-1,
      $qe-product-font-color-1
    );
  }
  &.scheme_2,
  &.scheme_2.nested-scheme {
    @include elementsByScheme(
      $qe-product-background-color-2,
      $qe-product-dim-background-color-2,
      $qe-product-font-color-2
    );
  }
  &.scheme_3,
  &.scheme_3.nested-scheme {
    @include elementsByScheme(
      $qe-product-background-color-3,
      $qe-product-dim-background-color-3,
      $qe-product-font-color-3
    );
  }
  &.scheme_4,
  &.scheme_4.nested-scheme {
    @include elementsByScheme(
      $qe-product-background-color-4,
      $qe-product-dim-background-color-4,
      $qe-product-font-color-4
    );
  }
  &.scheme_5,
  &.scheme_5.nested-scheme {
    @include elementsByScheme(
      $qe-product-background-color-5,
      $qe-product-dim-background-color-5,
      $qe-product-font-color-5
    );
  }
  &.scheme_6,
  &.scheme_6.nested-scheme {
    @include elementsByScheme(
      $qe-product-background-color-6,
      $qe-product-dim-background-color-6,
      $qe-product-font-color-6
    );
  }
  &.scheme_7,
  &.scheme_7.nested-scheme {
    @include elementsByScheme(
      $qe-product-background-color-7,
      $qe-product-dim-background-color-7,
      $qe-product-font-color-7
    );
  }
  &.scheme_8,
  &.scheme_8.nested-scheme {
    @include elementsByScheme(
      $qe-product-background-color-8,
      $qe-product-dim-background-color-8,
      $qe-product-font-color-8
    );
  }
  &.scheme_9,
  &.scheme_9.nested-scheme {
    @include elementsByScheme(
      $qe-product-background-color-9,
      $qe-product-dim-background-color-9,
      $qe-product-font-color-9
    );
  }
  &.scheme_10,
  &.scheme_10.nested-scheme {
    @include elementsByScheme(
      $qe-product-background-color-10,
      $qe-product-dim-background-color-10,
      $qe-product-font-color-10
    );
  }
  &.scheme_11,
  &.scheme_11.nested-scheme {
    @include elementsByScheme(
      $qe-product-background-color-11,
      $qe-product-dim-background-color-11,
      $qe-product-font-color-11
    );
  }
  &.scheme_12,
  &.scheme_12.nested-scheme {
    @include elementsByScheme(
      $qe-product-background-color-12,
      $qe-product-dim-background-color-12,
      $qe-product-font-color-12
    );
  }
  .product-metadata {
    display: flex;
    align-items: flex-start;
    padding: 5px;
    border: 1px solid transparent;
    margin-bottom: 20px;
    border-radius: 1px;
    .product-metadata-thumbnail {
      flex: 4;
      background-color: #fff;
      .default {
        height: 150px;
        text-align: center;
        svg {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .product-metadata-content {
      overflow: hidden;
      flex: 6;
      padding: 0 20px;
      .title {
        @extend %text-elipsis;
        margin: 15px 0;
        font-family: 'Varela Round', sans-serif;
        font-size: 20px;
      }
      p {
        margin-bottom: 15px;
      }
      ul.tag-wrapper,
      ul.contributor-wrapper {
        padding: 0;
        margin: 0;
        list-style-type: none;
      }
      ul.contributor-wrapper {
        li {
          display: flex;
          .name {
            margin-right: 20px;
            font-weight: 700;
            .type {
              font-weight: 400;
            }
          }
        }
      }
      ul.tag-wrapper {
        li {
          display: inline-block;
          height: 26px;
          padding: 0 5px;
          border: 1px solid $qe-secondary-background-color;
          margin: 2px;
          background: $qe-secondary-background-color;
          border-radius: 1px;
          color: $qe-secondary-font-color;
          cursor: default;
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          font-weight: 500;
          line-height: 25px;
        }
      }
    }
  }
}

.product-preview {
  display: flex;
  height: 100%;
  flex-direction: column;
  .product-viewer,
  .linked-tasks-viewer {
    width: 100%;
    flex: 1;
    overflow-x: hidden;
  }
}

.linked-tasks-viewer {
  display: flex;
  height: 100%;
  flex-direction: column;
  > .product-viewer {
    flex: 1;
  }
}

.product-preview-header {
  padding: 10px;
  text-align: right;
}

.product-viewer {
  display: flex;
  flex-direction: column;
  .product-viewer-header,
  .product-viewer-body {
    padding-top: 20px;
  }
  .product-viewer-header {
    .scheme-bg {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 15px;
      .logo {
        width: 100%;
        height: 50px;
        text-align: left;
      }
    }
    .product-item {
      display: inline-flex;
      align-items: flex-start;
      margin-top: 15px;
      margin-right: 15px;
    }
    .product-icon-wrapper {
      margin-right: 10px;
      svg {
        width: auto;
        height: 50px;
      }
    }
    .product-data-wrapper {
      font-family: 'Varela Round', sans-serif;
      font-size: 13px;
      .product-name {
        font-size: 23px;
      }
    }
    .product-group {
      margin-top: 15px;
    }
    .logo {
      svg {
        margin: 6px 0;
      }
    }
  }
  .product-viewer-body {
    flex: 1;
  }
  .product-cover {
    min-height: 500px;
    .row,
    .summary-wrapper {
      height: 100%;
    }
    .summary-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      h1 {
        margin-bottom: 20px;
      }
    }
    .thumbnail-wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    img {
      display: block;
      max-width: 100%;
      max-height: 350px;
      margin: 0 auto;
    }
  }

  .product-summary-placeholder {
    flex: 1;
    padding: 20px;
  }
  .product-summary {
    display: flex;
    justify-content: center;
    .info-box {
      width: 100px;
      height: 100px;
      border: 2px solid;
      margin: 0 10px;
      border-radius: 2px;
      text-align: center;
      .header {
        display: block;
        padding: 5px 0;
        font-size: 12px;
        font-weight: bold;
        line-height: 15px;
        text-transform: uppercase;
      }
      .icon {
        display: inline-block;
        margin-top: 10px;
        font-size: 30px;
        font-weight: bold;
        line-height: 30px;
        &.number {
          font-size: 42px;
          line-height: 42px;
        }
      }
      .info {
        display: block;
        padding: 5px 0;
        font-size: 12px;
        font-weight: bold;
        line-height: 10px;
        &.info-lg {
          margin-top: 15px;
          font-size: 26px;
          line-height: 26px;
        }
      }
    }
  }
  .product-viewer-content {
    min-height: 500px;
    padding: 15px 15px;
    background: #fff;
  }
}

.product-restarter {
  padding: 15px;
  text-align: center;
}

.product-footer {
  .footer-wrapper-inner {
    height: 40px;
    padding: 11px 0;
  }
}

@media (min-width: 768px) {
  .product .product-content-wrapper .product-menu .publication-wrapper .publish-functions {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .product .product-content-wrapper .product-menu .publication-wrapper .publish-functions {
    flex-basis: auto;
  }
  .product-viewer {
    .product-viewer-header,
    .product-viewer-body {
      padding-left: 40px;
    }
    .product-viewer-header {
      .scheme-bg {
        flex-wrap: nowrap;
        justify-content: flex-start;
        .logo {
          width: auto;
          flex: 1;
          order: 2;
          text-align: right;
        }
      }
      .product-item {
        margin-top: 0;
      }
    }

    .product-viewer-header .scheme-bg,
    .product-viewer-content {
      border-bottom-left-radius: 18px;
      border-top-left-radius: 18px;
    }

    .product-viewer-content {
      padding: 25px 25px;
    }
    .product-viewer-content-inner {
      padding-right: 30px;
      padding-left: 30px;
      margin-left: -40px;
    }
  }
}

@media (min-width: 1200px) {
  .product .product-content-wrapper .product-menu .publication-wrapper .publication-details {
    margin-bottom: 0;
  }
}
