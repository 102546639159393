.note-btn-group {
  .btn {
    min-width: auto;
    border-color: $edu-info-color;
    margin-bottom: 10px;
    background-color: #fff;
    color: $edu-info-color;
    font-size: 12px;
    &.active {
      border-color: $edu-info-color;
      background-color: $edu-info-color;
      color: #fff;
    }
  }
}
.note-editing-area .note-editable {
  color: $qe-tertiary-font-color;
  font-family: 'Montserrat', sans-serif;
}
.link-dialog {
  .modal-header {
    background: $edu-info-color;
    .close {
      display: none;
    }
  }
  label {
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 700;
    &.required:after {
      color: $qe-error-font-color;
      content: '*';
    }
  }
}

.text-editor {
  .panel-body {
    height: 300px;
  }
}
