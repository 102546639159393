* {
  box-sizing: border-box;
}

*[role='button'] {
  &:focus {
    outline: none;
  }
}

/* vietnamese */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url(../font/Montserrat/Montserrat-Light.ttf) format('truetype');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url(../font/Montserrat/Montserrat-Light.ttf) format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'),
    url(../font/Montserrat/Montserrat-Light.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2212, U+2215;
}

/* vietnamese */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url(../font/Montserrat/Montserrat-Regular.ttf) format('truetype');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url(../font/Montserrat/Montserrat-Regular.ttf) format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url(../font/Montserrat/Montserrat-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2212, U+2215;
}

/* vietnamese */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url(../font/Montserrat/Montserrat-Medium.ttf) format('truetype');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url(../font/Montserrat/Montserrat-Medium.ttf) format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url(../font/Montserrat/Montserrat-Medium.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2212, U+2215;
}

/* hebrew */

@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: local('Varela Round Regular'), local('VarelaRound-Regular'),
    url(../font/Varela_Round/VarelaRound-Regular.ttf) format('truetype');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* vietnamese */

@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: local('Varela Round Regular'), local('VarelaRound-Regular'),
    url(../font/Varela_Round/VarelaRound-Regular.ttf) format('truetype');
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */

@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: local('Varela Round Regular'), local('VarelaRound-Regular'),
    url(../font/Varela_Round/VarelaRound-Regular.ttf) format('truetype');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */

@font-face {
  font-family: 'Varela Round';
  font-style: normal;
  font-weight: 400;
  src: local('Varela Round Regular'), local('VarelaRound-Regular'),
    url(../font/Varela_Round/VarelaRound-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
    U+20AC, U+2212, U+2215;
}

body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}

.container {
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}

.has-inner-icon {
  position: relative;
  .inner-icon {
    position: absolute;
    right: 10px;
    width: auto;
    height: 18px;
    transform: translateY(-125%);
    svg {
      width: auto;
      height: 100%;
    }
  }
}

.page-top-arrow {
  height: 10px;
  background-color: $qe-tertiary-background-color;
  &:before,
  &:after {
    position: absolute;
    width: 50%;
    border-bottom: 10px solid $qe-primary-background-color;
    content: '';
  }
  &:before {
    right: 50%;
    border-right: 54px solid transparent;
  }
  &:after {
    left: 50%;
    border-left: 54px solid transparent;
  }
}

// New education package, product, source thumbnail
.new-entity-thumbnail-wrapper,
.entity-thumbnail-wrapper {
  height: 405px;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}

.new-entity-thumbnail-wrapper {
  .new-entity-thumbnail {
    width: 100%;
    height: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: $qe-secondary-background-color;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    .icon {
      position: relative;
      top: 25%;
      text-align: center;
      svg {
        fill: $qe-tertiary-font-color;
      }
    }
    .title {
      position: relative;
      top: 150px;
      padding: 0 40px;
      margin: 0;
      color: $qe-tertiary-font-color;
      font-family: 'Varela Round', sans-serif;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
    }
  }
}

.entity-thumbnail-wrapper {
  .entity-thumbnail-image {
    position: relative;
    height: 240px;
    background-color: #dee3e9;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    &.icon-container > div,
    &.default .thumbnail-fallback {
      position: relative;
      top: 50%;
      text-align: center;
      transform: translateY(-50%);
    }
    &.default .thumbnail-fallback {
      svg {
        fill: $qe-tertiary-font-color;
      }
    }
    .text-source-preview {
      overflow: hidden;
      height: 100%;
      padding: 15px;
      text-align: justify;
      p {
        overflow: hidden;
        max-height: 115%;
        background: -webkit-linear-gradient($qe-source-decorator-color 70%, #fff);
        -webkit-background-clip: text;
        background-clip: text;
        background-color: white;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .entity-thumbnail-metadata-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 165px;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 20px 20px;
    background: $qe-secondary-background-color;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: $qe-secondary-font-color;
    .cutout {
      position: absolute;
      top: -30px;
      right: 0;
      left: 0;
      width: 100%;
      height: 30px;
      fill: $qe-secondary-background-color;
    }
    .title {
      position: relative;
      overflow: hidden;
      max-height: 90px;
      padding-right: 30px;
      margin: 0 -20px 0 0;
      font-family: 'Varela Round', sans-serif;
      font-size: 25px;
      line-height: 30px;
      word-break: break-word;
      &:before {
        position: absolute;
        right: 15px;
        bottom: 0;
        content: '...';
      }
      &:after {
        position: absolute;
        right: 15px;
        width: 20px;
        height: 25px;
        margin-top: 0.2em;
        background: #22466a;
        content: '';
      }
    }
    .menu-wrapper {
      .menu-item {
        cursor: pointer;
        &:not(last-child) {
          display: inline-block;
        }
        &:first-child {
          padding-right: 10px;
        }
        &:last-child {
          float: right;
        }
        svg {
          fill: $qe-secondary-font-color;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.form-group {
  margin-bottom: 20px;
  .control-label {
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 700;
    &.required:after {
      color: $qe-error-font-color;
      content: '*';
    }
  }
  .form-control:not(textarea) {
    height: 28px;
  }
  textarea.form-control {
    resize: vertical;
  }
  .input-group {
    .input-group-btn {
      button {
        min-width: 45px;
        height: 28px;
        padding: 5px 10px;
        margin-bottom: 10px;
        border-radius: 0px;
      }
    }
  }
  .form-control {
    padding: 4px 10px;
    border: 1px solid $qe-input-border-color;
    background: $qe-input-background-color;
    border-radius: 1px;
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    option {
      padding: 3px 20px;
      background: $qe-input-background-color;
      color: $qe-tertiary-font-color;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 400;
      &:hover {
        background: $qe-input-hover-background-color;
        color: $qe-secondary-font-color;
      }
    }
  }
  .has-inner-icon {
    .inner-icon {
      svg {
        fill: $qe-tertiary-font-color;
      }
    }
  }
  .custom-tags-input {
    .host {
      margin: 0;
      .tags {
        padding: 0;
        border: 1px solid $qe-input-border-color;
        background: $qe-input-background-color;
        border-radius: 1px;
        .input {
          height: 26px;
          padding: 0;
          margin: 0;
          margin-left: 5px;
          background: $qe-input-background-color;
          color: $qe-tertiary-font-color;
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          font-weight: 500;
          line-height: 24px;
        }
        .tag-item {
          height: 22px;
          border: 1px solid $qe-secondary-background-color;
          margin: 2px;
          background: $qe-secondary-background-color;
          border-radius: 1px;
          color: $qe-secondary-font-color;
          cursor: default;
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          font-weight: 400;
          line-height: 21px;
          .remove-button {
            color: $qe-secondary-font-color;
            font: 13px 'Montserrat', sans-serif;
            text-decoration: none;
            vertical-align: inherit;
          }
          .tag-text {
            color: $qe-secondary-font-color;
            cursor: pointer;
            text-decoration: none;
          }
        }
      }
      .autocomplete {
        padding: 0;
        border: 1px solid $qe-input-border-color;
        margin: 5px 0 0;
        border-radius: 0;
        -webkit-box-shadow: 0 5px 10px rgba(#22466a, 0.2);
        -moz-box-shadow: 0 5px 10px rgba(#22466a, 0.2);
        box-shadow: 0 5px 10px rgba(#22466a, 0.2);
        .suggestion-item {
          padding: 3px 15px;
          background: $qe-input-background-color;
          color: $qe-tertiary-font-color;
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          font-weight: 400;
          em {
            color: $qe-tertiary-font-color;
            font-family: 'Montserrat', sans-serif;
            font-size: 13px;
            font-weight: 700;
          }
          &.selected em {
            background: $qe-input-background-color;
            color: $qe-tertiary-font-color;
          }
          &:hover,
          &.selected,
          &.selected:hover em {
            background: #22466a;
            color: #f6f6f6;
          }
        }
      }
    }
  }
  &.contributor-selection {
    .add {
      display: flex;
      height: 18px;
      color: $qe-tertiary-font-color;
      cursor: pointer;
      float: right;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 400;
      user-select: none;
      .icon {
        padding: 1px 0px;
        margin-right: 5px;
        text-align: right;
        svg {
          width: auto;
          height: 16px;
          fill: #22466a;
        }
      }
      .text {
        color: $qe-tertiary-font-color;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
        font-weight: 400;
      }
    }
    .empty-list {
      padding: 15px;
      border: 1px solid $qe-input-border-color;
      background: $qe-input-background-color;
      color: $qe-tertiary-font-color;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      text-align: center;
    }
    .list {
      display: flex;
      height: 28px;
      margin-bottom: 15px;
      .person {
        position: relative;
        flex: 10;
        padding-right: 15px;
      }
      .type {
        flex: 5;
        padding-right: 15px;
      }
      .remove {
        height: 100%;
        flex: 1;
        align-self: center;
        padding: 1.5px 0px;
        cursor: pointer;
        text-align: center;
        svg {
          fill: #22466a;
        }
        &:hover {
          opacity: 0.8;
        }
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
    ul.dropdown-menu {
      width: calc(100% - 15px);
      padding: 0;
      border: 1px solid $qe-input-border-color;
      margin: 5px 0 0;
      border-radius: 0;
      -webkit-box-shadow: 0 5px 10px rgba(#22466a, 0.2);
      -moz-box-shadow: 0 5px 10px rgba(#22466a, 0.2);
      box-shadow: 0 5px 10px rgba(#22466a, 0.2);
      li {
        > a {
          padding: 3px 15px;
          background: $qe-input-background-color;
          color: $qe-tertiary-font-color;
          font-family: 'Montserrat', sans-serif;
          font-size: 13px;
          font-weight: 400;
        }
        &.active > a {
          background: #22466a;
          color: #f6f6f6;
        }
      }
    }
  }
}

.uib-typeahead-match > div {
  display: block;
  padding: 3px 20px;
  clear: both;
  color: #333;
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  span {
    cursor: default;
  }
  a {
    margin-left: 15px;
    float: right;
  }
  &:hover {
    background-color: #337ab7;
    color: #fff;
    outline: 0;
    text-decoration: none;
    a {
      color: #fff;
    }
  }
}

.divider {
  border-top: 1px solid $qe-secondary-background-color;
  margin-top: 0px;
  margin-bottom: 20px;
}

.form-control-wrapper {
  display: flex;
  .form-control-item {
    flex: 1;
    align-self: center;
    text-align: center;
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: right;
    }
  }
  .page-status {
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 400;
  }
}

.has-error {
  .control-label {
    color: $qe-error-font-color !important;
  }
  .form-control {
    border-color: $qe-error-border-color !important;
    background: $qe-error-background-color !important;
    color: $qe-error-font-color !important;
  }
}

.ivh-treeview-wrapper {
  ul.ivh-treeview {
    margin-bottom: 0;
    .ivh-treeview-node-content-wrapper {
      padding: 4px 10px;
      margin-bottom: 1px;
      background: #d3dae1;
      clear: both;
      color: $qe-tertiary-font-color;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 500;
      .ivh-treeview-twistie-wrapper {
        display: inline-block;
        width: 18px;
        height: 18px;
        vertical-align: middle;
        .ivh-treeview-twistie-expanded {
          svg {
            transform: rotate(90deg);
          }
        }
        svg {
          width: auto;
          height: 100%;
          fill: $qe-tertiary-font-color;
        }
      }
      .ivh-treeview-node-label {
        @extend %text-elipsis;
        display: inline-block;
        max-width: 310px;
        vertical-align: middle;
      }
      .ivh-treeview-checkbox-wrapper {
        display: flex;
        width: 18px;
        height: 18px;
        padding: 4px 3px;
        border: 1px solid $qe-tertiary-font-color;
        border-radius: 2px;
        float: right;
        svg {
          width: auto;
          height: 100%;
          fill: $qe-tertiary-font-color;
        }
      }
      .ivh-treeview-control-wrapper {
        display: flex;
        margin-top: 3px;
        float: right;
        .ivh-treeview-control {
          width: 15px;
          height: 15px;
          margin-right: 10px;
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
          &:last-child {
            margin-right: 0;
          }
          svg {
            width: auto;
            height: 100%;
            fill: $qe-tertiary-font-color;
          }
        }
      }
    }
    ul.ivh-treeview {
      padding-left: 28px;
      .ivh-treeview-node-content-wrapper {
        background: #e8ecf0;
      }
      ul.ivh-treeview {
        .ivh-treeview-node-content-wrapper {
          background: #fff;
        }
      }
    }
  }
}

// toaster
.edu-toaster {
  .alert {
    width: 400px;
    min-height: 50px;
    padding: 17px 15px;
    border: none;
    margin-bottom: 0px;
    border-radius: 0px;
    color: $qe-secondary-font-color;
  }
  .alert-success {
    background-color: $qe-success-color;
  }
  .alert-warning,
  .alert-danger {
    background-color: #f04614;
  }
  .alert-info {
    background-color: $qe-source-decorator-color;
  }
}

.edu-toaster.ng-toast__message {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.custom-nav-tabs {
  .hide-tab > .nav.nav-tabs {
    display: none;
  }
  .nav-tabs {
    height: 36px;
    margin-bottom: 20px;
    .nav-item {
      border: 1px solid $qe-source-decorator-color;
      border-right: none;
      &:first-child {
        border-bottom-left-radius: 18px;
        border-top-left-radius: 18px;
      }
      &:last-child {
        border-right: 1px solid $qe-source-decorator-color;
        border-bottom-right-radius: 18px;
        border-top-right-radius: 18px;
      }
      .nav-link {
        padding: 7px 10px;
        border: 0;
        background: transparent;
        border-radius: 0;
        color: $qe-source-decorator-color;
        font-family: 'Varela Round', sans-serif;
        font-size: 18px;
        line-height: 20px;
      }
      &:hover,
      &.active {
        background: $qe-source-decorator-color;
        .nav-link {
          border: 0;
          color: #fff;
        }
      }
    }
  }
}

.uib-daypicker .btn {
  min-width: auto;
}

.uib-monthpicker .btn {
  min-width: 100px;
}

.uib-datepicker {
  *:focus {
    outline: none;
  }
  table {
    margin: 10px auto; // Fixes the datepicker header buttons overflowing the <th> for reasons unknown...
    th button {
      max-width: 95%;
    }
    th[colspan='3'] button {
      max-width: 98%;
    }
    th[colspan='6'] button {
      max-width: 98.5%;
    }
  } // Reset bootstrap btn defaults for the datepicker
  .btn {
    padding: 6px 12px;
    margin: 2px; // this one's custom
  }
  .btn-sm {
    padding: 5px 12px;
  }
  .btn-default {
    // border-color: #ccc;
    border-width: 1px;
  }
}

i:focus {
  outline: none;
}

.panel-body {
  .panel-title {
    display: inline-block;
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
  }
}

.pagination[uib-pagination] *:focus {
  outline: none;
}

.error-page {
  .title,
  .message {
    text-align: center;
  }
  .title {
    margin-bottom: 25px 0;
    color: $qe-error-font-color;
  }
  .message {
    color: $qe-tertiary-font-color;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 500;
  }
}

.checkbox {
  margin: 0;
}

.source-info {
  color: $qe-secondary-background-color;
}

.printable .source-caption a {
  color: #22466a;
  &:hover {
    text-decoration: none;
  }
  &[href]:after {
    content: '';
  }
}

.pagination {
  margin: 0;
  li {
    &:first-child a,
    &:first-child span {
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;
    }
    &:last-child a,
    &:last-child span {
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
    }
    a,
    span {
      padding: 4px 12px;
      border-color: $qe-secondary-background-color;
      background: $qe-primary-background-color;
      color: $qe-tertiary-font-color;
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      &:hover {
        border-color: $qe-secondary-background-color;
        background: $qe-primary-background-color;
        color: $qe-tertiary-font-color;
      }
    }
    &.disabled a,
    &.disabled span {
      background: $qe-input-background-color;
    }
    &.active a,
    &.active a:hover,
    &.active span,
    &.active span:hover {
      border-color: $qe-input-hover-background-color;
      background: $qe-input-hover-background-color;
      color: $qe-secondary-font-color;
    }
  }
}

.tl-caption {
  color: #22466a;
  font-style: italic;
  text-align: center;
}

.tooltip .tooltip-inner {
  max-width: 600px;
  word-break: break-word;
}

.icon-infinite::before {
  display: inline-block;
  content: '8';
  transform: rotate(-90deg);
}

.printView {
  display: none;
}
