.wordfind-page {
  .wordfind-form {
    padding-bottom: 15px;

    .word-matrix-wrapper {
      padding-left: 0px;
      .word-select-button {
        margin-bottom: 10px;
      }
    }

    .word-list-wrapper {
      padding-right: 0px;
    }

    textarea {
      resize: vertical;
    }

    .words {
      padding: 0;
      margin: 0;
      list-style-type: none;

      .word-wrapper {
        display: flex;
        margin-bottom: 10px;

        .word {
          @extend %text-elipsis;
          flex: 9;
          color: $qe-tertiary-font-color;
        }
        .word-delete {
          flex: 1;
        }
      }
      .word-wrapper.has-error {
        .word,
        .word-delete {
          color: $qe-error-font-color;
        }
      }
    }

    .selected-labels-caption {
      padding-top: 5px;
      margin-bottom: 10px;
    }

    .wordfind-editor-caption {
      padding-top: 5px;
    }
  }

  .add-btn i {
    color: #4e7649;
  }
}
