ui-view {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.main-content {
  position: relative;
  flex: 1;
}
