media-preview {
  position: relative;
  display: block;
  height: 100%;
  background-color: #000000;
  .copyrighted-source {
    position: absolute;
    z-index: 10;
    top: 2px;
    right: 2px;
    color: $qe-error-font-color;
  }
}
