.slideshow-viewer {
  height: auto;

  .slideshow-content {
    position: relative;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  .slideshow-metadata-wrapper {
    position: relative;
    overflow: hidden;
    padding-right: 30px;
    padding-left: 30px;
    &.top {
      margin-bottom: 25px;
    }
    &.bottom {
      margin-top: 25px;
    }
    .slideshow-title {
      margin: 0 auto;
      margin-bottom: 10px;
      font-size: 30px;
      text-align: center;
    }
    .slideshow-description {
      margin: 0 auto;
      text-align: center;
    }
  }
  .slideshow-source-wrapper {
    iframe.source-media {
      min-height: 350px;
    }
    .slideshow-source-text-wrapper {
      position: relative;
      padding: 20px;
      border: 2px solid #ddd;
      border-radius: 10px;
      &.left {
        margin-bottom: 25px;
      }
      &.right {
        margin-top: 25px;
      }
      .slideshow-source-text {
        max-height: 100%;
        padding: 5px;
        overflow-y: auto;
      }
    }
  }
  .slideshow-slide-previous,
  .slideshow-slide-next {
    position: absolute;
    display: block;
    cursor: pointer;
    &.fa-2x {
      top: 8px;
    }
    &.fa-3x {
      top: calc(50% - 15px);
    }
    &.disabled {
      visibility: hidden;
    }
    &:focus {
      outline: none;
    }
  }
  .slideshow-slide-previous {
    left: 0;
  }
  .slideshow-slide-next {
    right: 0;
  }
  .restart {
    padding: 15px;
  }
}

@media (min-width: 768px) {
  .slideshow-viewer {
    .slideshow-source-wrapper {
      display: flex;
      .slideshow-source-media-wrapper,
      .slideshow-source-text-wrapper {
        height: 550px;
      }
      .slideshow-source-media-wrapper {
        min-width: 0;
        flex: 1;
      }

      .slideshow-source-text-wrapper {
        flex: 1;
        .slideshow-source-text {
          height: 550px;
        }
        &.left {
          margin-right: 25px;
          margin-bottom: 0;
        }
        &.right {
          margin-top: 0;
          margin-left: 25px;
        }
      }
    }
    .media-view-standalone {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media (min-width: 992px) {
  .slideshow-viewer {
    .slideshow-metadata-wrapper,
    .slideshow-source-wrapper {
      padding-right: 55px;
      padding-left: 55px;
    }

    .slideshow-metadata-wrapper .slideshow-title {
      font-size: 36px;
    }

    .slideshow-source-wrapper {
      position: relative;
    }
  }
}
@media (min-width: 1360px) {
  .slideshow-viewer .slideshow-content {
    width: 1280px;
  }
}
