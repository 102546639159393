.puzzle-viewer {
  height: 100%;
  padding: 50px;
  &.product-viewer-content {
    min-height: auto;
  }

  .puzzle-content {
    width: 100%;
    user-select: none;
    .puzzle-image-wrapper,
    #jigsaw-wrapper {
      height: 300px;
    }
    media-view-standalone {
      position: relative;
      height: 80%;
      img {
        display: block;
        max-width: 100%;
        height: 100%;
        margin: 0 auto 10px;
      }
    }

    &.puzzle-ready {
      align-items: stretch;
      .text-center {
        margin-top: 20px;
      }
    }
    .end-message {
      height: auto;
      margin-top: 15px;
      .congratulation {
        margin-bottom: 15px;
        > *:first-child {
          margin-top: 0;
        }
        > *:last-child {
          margin-bottom: 0;
        }
      }
    }

    &.time-limit-reached {
      .em-title {
        display: block;
      }
      .puzzle-image {
        filter: grayscale(80%);
        opacity: 0.5;
      }
    }

    h3 {
      margin-top: 0px;
    }

    #jigsaw-wrapper {
      position: relative;
      text-align: center;

      &.hide-image {
        img,
        .puzzle {
          visibility: hidden;
        }
      }
    }

    #jigsaw-puzzle {
      position: absolute;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      pointer-events: none;

      img {
        max-width: 100%;
        max-height: 100%;
        opacity: 0.3;
        user-select: none;

        &.visibility-hidden {
          visibility: hidden;
        }
      }

      div.jigsaw {
        position: absolute;
        box-sizing: content-box;

        .puzzle {
          background-blend-mode: luminosity, normal;
          background-color: rgba(250, 250, 250, 0.8);
          opacity: 0.3;
        }
      }

      div.piece-container {
        position: absolute;
        overflow: hidden;
        pointer-events: none;

        &:focus {
          outline: none;
        }

        &.placed {
          // svg.piece-border,
          svg.piece-shadow {
            visibility: hidden;
          }
        }

        &[data-rotation='0'] svg.piece-shadow {
          top: 3px;
          left: 3px;
        }

        &[data-rotation='90'] svg.piece-shadow {
          top: -3px;
          left: 3px;
        }

        &[data-rotation='180'] svg.piece-shadow {
          top: -3px;
          left: -3px;
        }

        &[data-rotation='270'] svg.piece-shadow {
          top: 3px;
          left: -3px;
        }

        div.piece {
          position: absolute;
          z-index: -1;
          top: 0px;
          left: 0px;
          pointer-events: all;
        }

        svg.piece-border {
          position: absolute;
          z-index: 100000;
          top: 0;
          left: 0;
          overflow: visible;
          pointer-events: none;
        }

        svg.piece-shadow {
          position: absolute;
          overflow: visible;
          opacity: 0.75;
          pointer-events: none;
        }
      }
    }

    .jigsaw-content {
      overflow-y: scroll;
    }
  }
}

@media (min-width: 992px) {
  .puzzle-viewer {
    .puzzle-content {
      width: 100%;
      padding: 0px 50px;
      .puzzle-image-wrapper,
      #jigsaw-wrapper {
        height: 600px;
        padding: 0 30px;
      }

      &.puzzle-finished {
        .end-message {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: 0px;
          .congratulation {
            flex: 1;
            overflow-y: auto;
          }
        }
        &:not(.no-message) {
          .end-message {
            height: 600px;
          }
        }
      }
    }
  }
}
