.media-selector-modal {
  .modal-header {
    background: $qe-source-decorator-color;
  }
  .modal-body {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    .nav.nav-tabs {
      display: inline-block;
      border: none;
      li {
        a {
          position: relative;
          width: 100%;
          padding: 7px 10px;
          border: 1px solid $qe-source-decorator-color;
          margin: 0px;
          border-radius: 0px;
          cursor: pointer;
          float: left;
          font-family: 'Varela Round', sans-serif;
          font-size: 18px;
          line-height: 20px;
        }
        &:hover,
        &:focus,
        &.active {
          a {
            background: $qe-source-decorator-color;
            color: #ffffff;
          }
        }
        &:first-child {
          a {
            border-bottom-left-radius: 15px;
            border-top-left-radius: 15px;
          }
        }
        &:last-child {
          a {
            border-bottom-right-radius: 15px;
            border-top-right-radius: 15px;
          }
        }
      }
    }
  }
}
.media-selector {
  .empty-list-message {
    margin-top: 20px;
  }
  .source-wrapper {
    .source {
      padding: 3px;
      border: 1px solid $qe-source-decorator-color;
      margin-top: 20px;
      background-color: rgba($qe-source-decorator-color, 0.4);
      color: $qe-source-decorator-color;
      text-align: center;
      &:focus,
      &:hover,
      &.selected {
        background: $qe-source-decorator-color;
        .title {
          color: white;
        }
      }
      media-preview {
        position: relative;
        height: 120px;
        background-color: #000000;
        text-align: center;
        &:before {
          display: inline-block;
          height: 100%;
          content: '';
          vertical-align: middle;
        }
        img {
          max-width: 100%;
          max-height: 120px;
        }
      }
      .title {
        @extend %text-elipsis;
        display: block;
        padding: 7px;
      }
    }
  }
}
